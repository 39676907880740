import React from "react";
import Chart from "react-apexcharts";

export default function Items() {
  return (
    <div className="row cus-row">
      <div className="col-xl-6 ">
        <div className="row cus-analytics-box">
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 11*/}
            <div
              className="card card-custom bg-danger gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                  {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Layout/Layout-4-blocks.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M18.1446364,11.84388 L17.4471627,16.0287218 C17.4463569,16.0335568 17.4455155,16.0383857 17.4446387,16.0432083 C17.345843,16.5865846 16.8252597,16.9469884 16.2818833,16.8481927 L4.91303792,14.7811299 C4.53842737,14.7130189 4.23500006,14.4380834 4.13039941,14.0719812 L2.30560137,7.68518803 C2.28007524,7.59584656 2.26712532,7.50338343 2.26712532,7.4104669 C2.26712532,6.85818215 2.71484057,6.4104669 3.26712532,6.4104669 L16.9929851,6.4104669 L17.606173,3.78251876 C17.7307772,3.24850086 18.2068633,2.87071314 18.7552257,2.87071314 L20.8200821,2.87071314 C21.4717328,2.87071314 22,3.39898039 22,4.05063106 C22,4.70228173 21.4717328,5.23054898 20.8200821,5.23054898 L19.6915238,5.23054898 L18.1446364,11.84388 Z"
                        fill="#000000"
                        opacity="0.8"
                      />
                      <path
                        d="M6.5,21 C5.67157288,21 5,20.3284271 5,19.5 C5,18.6715729 5.67157288,18 6.5,18 C7.32842712,18 8,18.6715729 8,19.5 C8,20.3284271 7.32842712,21 6.5,21 Z M15.5,21 C14.6715729,21 14,20.3284271 14,19.5 C14,18.6715729 14.6715729,18 15.5,18 C16.3284271,18 17,18.6715729 17,19.5 C17,20.3284271 16.3284271,21 15.5,21 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-inverse-success font-weight-bolder font-size-h3 mt-3">
                  790
                </div>
                <a
                  href="#"
                  className="text-inverse-success font-weight-bold font-size-lg mt-1"
                >
                  Live Orders
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 11*/}
          </div>
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 12*/}
            <div
              className="card card-custom gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Group.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-dark font-weight-bolder font-size-h3 mt-3">
                  20
                </div>
                <a
                  href="#"
                  className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                >
                  Critical Orders
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 12*/}
          </div>
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 12*/}
            <div
              className="card card-custom gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Group.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-dark font-weight-bolder font-size-h3 mt-3">
                  ₹ 18,450
                </div>
                <a
                  href="#"
                  className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                >
                  Total Revenue
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 12*/}
          </div>
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 12*/}
            <div
              className="card card-custom gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Design/Bucket.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M5,5 L5,15 C5,15.5948613 5.25970314,16.1290656 5.6719139,16.4954176 C5.71978107,16.5379595 5.76682388,16.5788906 5.81365532,16.6178662 C5.82524933,16.6294602 15,7.45470952 15,7.45470952 C15,6.9962515 15,6.17801499 15,5 L5,5 Z M5,3 L15,3 C16.1045695,3 17,3.8954305 17,5 L17,15 C17,17.209139 15.209139,19 13,19 L7,19 C4.790861,19 3,17.209139 3,15 L3,5 C3,3.8954305 3.8954305,3 5,3 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(10.000000, 11.000000) rotate(-315.000000) translate(-10.000000, -11.000000) "
                      />
                      <path
                        d="M20,22 C21.6568542,22 23,20.6568542 23,19 C23,17.8954305 22,16.2287638 20,14 C18,16.2287638 17,17.8954305 17,19 C17,20.6568542 18.3431458,22 20,22 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-dark font-weight-bolder font-size-h3 mt-3">
                  600
                </div>
                <a
                  href="#"
                  className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                >
                  Total Unit Sold
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 12*/}
          </div>
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 12*/}
            <div
              className="card card-custom gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Shopping/Money.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z"
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "
                      />
                      <path
                        d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-dark font-weight-bolder font-size-h3 mt-3">
                  ₹10
                </div>
                <a
                  href="#"
                  className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                >
                  Total COGS
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 12*/}
          </div>
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 12*/}
            <div
              className="card card-custom gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Shopping/Chart-line1.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-dark font-weight-bolder font-size-h3 mt-3">
                  ₹ 3,380
                </div>
                <a
                  href="#"
                  className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                >
                  Total Profit
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 12*/}
          </div>
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 12*/}
            <div
              className="card card-custom gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Home/Trash.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-dark font-weight-bolder font-size-h3 mt-3">
                  350
                </div>
                <a
                  href="#"
                  className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                >
                  Total Waste Units
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 12*/}
          </div>
          <div className="col-xl-3 col-sm-6  col-md-4">
            {/*begin::Tiles Widget 12*/}
            <div
              className="card card-custom gutter-b"
              style={{ height: "150px" }}
            >
              <div className="card-body pr-0">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Shopping/Chart-line2.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(14.000019, 10.749981) scale(1, -1) translate(-14.000019, -10.749981) "
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
                <div className="text-dark font-weight-bolder font-size-h3 mt-3">
                  ₹ 8,450
                </div>
                <a
                  href="#"
                  className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
                >
                  Total Waste Revenue
                </a>
              </div>
            </div>
            {/*end::Tiles Widget 12*/}
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        {/*begin::Mixed Widget 14*/}
        <div className="card card-custom bdr  gutter-b card-stretch" style={{}}>
          {/*begin::Body*/}
          <div className="card-body pb-0 pt-0">
            <Chart
              options={{
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                xaxis: {
                  type: "datetime",
                  categories: [
                    "2018-09-19T00:00:00.000Z",
                    "2018-09-19T01:30:00.000Z",
                    "2018-09-19T02:30:00.000Z",
                    "2018-09-19T03:30:00.000Z",
                    "2018-09-19T04:30:00.000Z",
                    "2018-09-19T05:30:00.000Z",
                    "2018-09-19T06:30:00.000Z",
                  ],
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
                colors: ["#6993FF", "#1BC5BD"],
              }}
              series={[
                {
                  name: "series1",
                  data: [31, 40, 28, 51, 42, 109, 100],
                },
                {
                  name: "series2",
                  data: [11, 32, 45, 32, 34, 52, 41],
                },
              ]}
              type="area"
              height={300}
            />
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Mixed Widget 14*/}
      </div>
    </div>
  );
}
