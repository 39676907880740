import React, { useEffect, useState } from "react";
import QrReader from "react-qr-scanner";

export default function Camera({ onScan }) {
  const [error, setError] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [facingMode, setFacingMode] = useState("rear");
  let isDesktop = width > 880;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleScan = (data) => {
    if (data !== null) {
      // setValue(data.text);
      // console.log(data);
      onScan(data.text);
      // props.onScan(data)
    }
  };

  const handleError = (err) => {
    console.log(err);
    setError(err.message);
    // setError(err.message)
    // props.onError(err)
  };

  const cameraStyle = {
    width: "90%",
    borderRadius: "10px",
    transform: "rotateY(180deg)",
  };

  const rearCameraStyle = {
    width: "90%",
    borderRadius: "12px",
  };
  return (
    <>
      {error ? (
        <div>
          <p>Camera Error</p>
          <p>{error}</p>
        </div>
      ) : (
        <div>
          <div
            className=" mx-auto rounded mb-3"
            // style={{ width: "140px", height: "140px" }}
          >
            {facingMode === "front" || isDesktop ? (
              <QrReader
                delay={0}
                style={cameraStyle}
                onError={handleError}
                onScan={handleScan}
                facingMode={"front"}
              />
            ) : (
              <QrReader
                delay={0}
                style={rearCameraStyle}
                onError={handleError}
                onScan={handleScan}
                constraints={
                  isDesktop
                    ? undefined
                    : {
                        video: {
                          facingMode: { exact: `environment` },
                        },
                      }
                }
              />
            )}
          </div>
          <p>
            Scan the QR Code on the thermos
            {!isDesktop && (
              <button
                onClick={() => {
                  if (facingMode === "rear") setFacingMode("front");
                  else setFacingMode("rear");
                }}
                className="btn btn-icon btn-light ml-2"
              >
                <i className="flaticon-refresh" />
              </button>
            )}
          </p>
        </div>
      )}
    </>
  );
}
