import React, { useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
export default function() {
  const [filters, setFilters] = useState({
    search: "",
    type: "",
    status_: "",
  });

  return (
    <>
      <div>
        <Card className="card-spaces">
          <CardBody className="p-4">
            <TableFilter
              status={filters.status}
              setSearchString={(value) => {
                let obj = { ...filters };
                obj.search = value;
                setFilters(obj);
              }}
              setStatusFilter={(value) => {
                let obj = { ...filters };
                obj.status_ = value;
                setFilters(obj);
              }}
              onClearFilter={() => {
                setFilters({
                  search: "",
                  type: "",
                  status_: "",
                });
              }}
            />
          </CardBody>
        </Card>
        <Card>
          <Table filters={filters} />
        </Card>
      </div>
    </>
  );
}
