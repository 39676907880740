import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";

export default function ({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);
  const [reason, setReason] = useState("");

  const onSubmit = () => {
    if (reason) {
      const body = {
        reason: reason,
      };
      setSending(true);
      getApiData(`tapri/v1/batch/${data.id}/`, "delete_body", body).then(
        (res) => {
          setSending(false);
          if (res.status) {
            onHide();
            onSuccess(res.message);
          } else {
            onError(res.message);
          }
        }
      );
    }
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Cancel Batch{" "}
          <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Batch:</span> 1997
          </span>
        </Modal.Title>
        <button className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div class="row mt-5">
          <div class="col-md-12 ">
            <label for="" class="font-weight-bolder">
              Reason
            </label>
            <textarea
              class="form-control form-control-lg form-control-solid "
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
              rows={3}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        <button
          className="btn btn-danger font-weight-bold px-10"
          disabled={reason && !sending ? false : true}
          onClick={onSubmit}
        >
          Submit{" "}
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
      </div>
    </Modal>
  );
}
