import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GeneralDropdown } from "../../../../components";
import { getApiData } from "../../../../Services";

export default function({ show, onHide, data, onSuccess, onError }) {
  const [selectedId, setSelectedId] = useState("");
  const [type, setType] = useState("");
  const [list, setList] = useState([]);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (type) {
      getList();
    }
  }, [type]);

  const getList = () => {
    getApiData(`v1/${type}/?limit=1000`).then((res) => {
      if (res.status) {
        let list = [];
        res.data.map((item) => {
          let label = item.name ? item.name : item.first_name;
          let value = type === "tapris" ? item.manager : item.id;
          list = [...list, { label: label, value: value }];
        });
        setList(list);
      }
    });
  };
  const onSubmit = () => {
    if (selectedId) {
      const body = {
        new_owner: selectedId,
      };
      setSending(true);
      getApiData(`tapri/v1/thermos/${data.utensil_id}/`, "put", body).then(
        (res) => {
          setSending(false);
          if (res.status) {
            setList([]);
            setSelectedId("");
            onHide();
            onSuccess(res.message);
          } else {
            onError(res.message);
          }
        }
      );
    }
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Move Thermos{" "}
          <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Code:</span> {data.code}
          </span>
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3">
        <div className="row">
          <div className="col-lg-12 mb-5 mb-md-5">
            <label htmlFor className="font-weight-bolder">
              Type
            </label>
            <select
              className="form-control form-control-lg form-control-solid "
              onChange={(e) => {
                setSelectedId(null);
                setList([]);
                setType(e.target.value);
              }}
            >
              <option value disabled selected>
                Select Type
              </option>
              <option value="tapris">Tapri</option>
              <option value="riders">Rider</option>
              <option value="chhotus">Chhotu</option>
            </select>
          </div>

          <div className="col-md-12 ">
            <label htmlFor className="font-weight-bolder">
              Select {type}
            </label>
            <GeneralDropdown
              selectedOption={selectedId}
              inModal
              list={list}
              handleChange={(obj) => {
                setSelectedId(obj.value);
                // setUnit(obj.data);
                // console.log(obj);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-danger font-weight-bold px-10"
          onClick={onSubmit}
          disabled={selectedId && !sending ? false : true}
        >
          Submit
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
      </div>
    </Modal>
  );
}
