import React from "react";
import { Modal } from "react-bootstrap";

export default function ({ show, onHide, onSuccess, onError }) {
  // const [modifiedGroupList, setModifiedGroupList] = useState([]);

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Report{" "}
          <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Thermos:</span> 1997
          </span>
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3">
        <div class="row">
          <div class="col-md-12 mb-8"></div>
          <div class="col-md-12 mb-5 mb-md-5">
            <label for="" class="font-weight-bolder">
              Select Reason
            </label>
            <select
              name=""
              class="form-control form-control-lg form-control-solid "
              id=""
            >
              <option value="" disabled="" selected="">
                Select Reason
              </option>
              <option value=""></option>
            </select>
          </div>
          <div class="col-md-12 ">
            <label for="" class="font-weight-bolder">
              Add Remarks
            </label>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid "
              id=""
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-light font-weight-bold px-10"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-danger font-weight-bold px-10">
          Report
        </button>
      </div>
    </Modal>
  );
}
