import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  SwitchUser: "[SwitchUser] Action",
  BackToAdmin: "[BackToAdmin] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  UpdateShowing: "[UpdateShowing] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  showing: undefined,
};

export const reducer = persistReducer(
  { storage, key: "userInfo", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        // console.log('login resuder')
        // console.log(action.payload)

        const { auth_token, user_data } = action.payload;
        localStorage.setItem("userData", JSON.stringify(user_data));
        // localStorage.setItem("currency_rate", JSON.stringify(currencyCode));
        // localStorage.setItem(
        //   "currency",
        //   JSON.stringify({
        //     lang: "in",
        //     name: "INR",
        //     flag: "/media/currency/inr.png",
        //     symbol: "₹",
        //   })
        // );
        return { authToken: auth_token };
      }

      case actionTypes.UpdateShowing: {
        const showing = action.payload;
        return { ...state, showing };
      }

      case actionTypes.SwitchUser: {
        const { auth_token, user_data } = action.payload;
        let adminAuth = JSON.parse(localStorage.getItem("persist:userInfo"));
        let adminData = JSON.parse(localStorage.getItem("userData"));
        let adminObj = JSON.stringify({
          token: adminAuth.authToken,
          data: adminData,
        });
        localStorage.setItem("adminData", adminObj);
        localStorage.setItem("userData", JSON.stringify(user_data));

        return { user: user_data, authToken: auth_token };
      }

      case actionTypes.BackToAdmin: {
        // console.log('BackToAdmin')
        const adminData = JSON.parse(localStorage.getItem("adminData"));
        localStorage.removeItem("adminData");
        // console.log(adminData.data);
        // console.log("token");
        let token = adminData.token;
        token = token.replace('"', "");
        token = token.replace('"', "");
        // console.log(token);
        localStorage.setItem("userData", JSON.stringify(adminData.data));
        return { user: adminData.data, authToken: token };

        // const { auth_token, user_data } = action.payload
        // let adminAuth = JSON.parse(localStorage.getItem('persist:userInfo'))
        // let adminData = JSON.parse(localStorage.getItem('userData'))
        // let adminObj = JSON.stringify({
        //   token: adminAuth.authToken,
        //   data: adminData,
        // })
        // localStorage.setItem('adminData', adminObj)
        // return { user: user_data, authToken: auth_token }
        // return { ...state };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        localStorage.removeItem("userData");
        localStorage.removeItem("adminData");
        localStorage.removeItem("device_token");
        // localStorage.removeItem("currency_rate");
        // localStorage.removeItem("currency");

        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (data) => ({ type: actionTypes.Login, payload: data }),
  switchUser: (data) => ({ type: actionTypes.SwitchUser, payload: data }),
  updateShowing: (data) => ({
    type: actionTypes.UpdateShowing,
    payload: data,
  }),
  backToAdmin: () => ({ type: actionTypes.BackToAdmin, payload: {} }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
