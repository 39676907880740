import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  CardBody,
  CardHeader,
  Pagination,
} from "../../../../_metronic/_partials/controls";
import { DeleteModal, Loader } from "../../../components";
import {
  convertDate,
  getParams,
  PER_PAGE,
  sizePerPageList,
  timeSince,
} from "../../../config";
import { fetchToken } from "../../../config/firebase";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../Services";
import { UserModal } from "./../modals";

function UserTable(props) {
  const { authToken } = useSelector((state) => state.auth);

  const history = useHistory();
  const [userModal, setUserModal] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`v1/users/${params}`, "get", {}, true, authToken).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        // alert(authToken);
        history.push("/logout");
      }
    });
  };

  const switchUser = (name, id, type) => {
    getApiData(`v1/users/switch`, "post", {
      user_id: id,
    }).then((res) => {
      toast.dismiss();
      if (res.status) {
        const data = {
          auth_token: res.data.token,
          user_data: {
            id: id,
            name: name,
            user_type: type,
            email: `${name}@gmail.com`,
            photo: null,
          },
        };
        fetchToken(res.data.token, true);
        props.switchUser(data);
        toast.success("Success");
        history.push("/dashboard");
      } else {
        toast.error(res.message);
      }
    });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "first_name",
      text: "Name",
      headerStyle: () => {
        return { minWidth: "150px" };
      },
      formatter: (_, row) => (
        <span>{row.first_name + " " + row.last_name}</span>
      ),
    },
    {
      dataField: "group",
      text: "Type",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "username",
      text: "Username",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "id",
      text: "Switch User",
      formatter: (_, row) => {
        let type = row.groups[0] ? row.groups[0] : "";

        if (type !== "customer" && type !== "admin") {
          return (
            <span>
              <a
                class="d-flex align-items-center"
                onClick={() => {
                  if (type === "building_acquisition") {
                    toast("Coming soon", {
                      duration: 6000,
                    });
                  } else {
                    toast.loading("Switching...");
                    switchUser(row.first_name, row.id, type);
                  }
                }}
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Sign-in.svg"
                    )}
                  />
                </span>
                <span
                  class="font-weight-bold text-dark ml-2"
                  style={{ width: "80px" }}
                >
                  Switch User
                </span>
              </a>
            </span>
          );
        } else {
          return null;
        }
      },
    },
    {
      dataField: "created_at",
      text: "Created At",
      headerStyle: () => {
        return { minWidth: "130px" };
      },
      formatter: (_, row) => (
        <div>
          <span>{convertDate(row.date_joined)}</span>
          <br />
          <span className="timeStampColor">({timeSince(row.date_joined)})</span>
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (_, row) => (
        <>
          <button
            className="btn btn-icon btn-light btn-hover-secondary btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setUserModal(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-secondary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </button>
          <> </>

          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setDeleteModal(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </>
      ),
      // classes: 'text-right pr-0',
      // headerClasses: 'text-right pr-3',
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };
  return (
    <>
      <UserModal
        show={userModal}
        groupList={props.groupList}
        data={selectedRow}
        onHide={() => setUserModal(!userModal)}
        onSuccess={(message) => {
          setUserModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <CardHeader className="px-0 mt-4 custom-card-header">
        <div className="row col-sm-12 pr-0 ">
          <div>
            <button
              onClick={() => {
                setSelectedRow({});
                setUserModal(true);
              }}
              className="btn btn-primary float-right"
            >
              Add New User
            </button>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {/* {selectedRow && ( */}
        <DeleteModal
          show={deleteModal}
          api="v1/users"
          title="Delete user"
          message="Are you sure to permanently delete this user?"
          onHide={() => setDeleteModal(!deleteModal)}
          id={selectedRow.id}
          onSuccess={(message) => {
            getTableRecords();
            toast.success(message);
          }}
          onFailed={(message) => {
            toast.error(message);
          }}
        />
        {/* // )} */}
        {loading ? (
          <Loader />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    onTableChange={() => {}}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    id="userTable"
                    bootstrap4
                    keyField="id"
                    data={data === null ? [] : data}
                    columns={columns}
                    // cellEdit={cellEditFactory({
                    //   mode: "click",
                    //   blurToSave: true,
                    // })}
                    noDataIndication={() => {
                      return "No Data to Display";
                    }}
                    defaultSorted={[{ dataField: "id", order: "asc" }]}
                    {...paginationTableProps}
                  >
                    {/* <PleaseWaitMessage entities={data} /> */}
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )}
      </CardBody>
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
