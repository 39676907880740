import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";

export default function({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");

  useEffect(() => {
    if (data.id) {
      console.log(data);
      setName(data.name);
      setUnit(data.measurement_unit);
    } else {
      setName("");
      setUnit("");
    }
  }, [data]);

  const onSubmit = () => {
    if (name && unit) {
      setSending(true);

      const body = {
        name: name,
        measurement_unit: unit,
      };

      let method = data.id ? "put" : "post";
      let param = data.id ? data.id + "/" : "";

      getApiData(`v1/ingredients/${param}`, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message, res.data);
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError("Something went wrong!");
          }
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Ingredient</Modal.Title>
        <button unit="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 form-group">
            <label>Name</label>
            <input
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 form-group">
            <label>Unit</label>
            <input
              className="form-control"
              value={unit}
              onChange={(e) => {
                setUnit(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          unit="button"
          className="btn btn-primary px-5"
          onClick={onSubmit}
          disabled={name && unit && !sending ? false : true}
        >
          Submit
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button unit="button" className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
