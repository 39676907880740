import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { timeSince } from "../../config";
import { getApiData } from "../../Services";

export function MapContainer({ google }) {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [lat, setLat] = useState(23.039982);
  const [lon, setLon] = useState(72.5074483);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setSelectedPlace(props.data);
    setShowingInfoWindow(true);
  };
  const onMapClicked = () => {
    if (showingInfoWindow) {
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };

  useEffect(() => {
    getCurrentLocation();
    getList();
  }, []);

  const getList = () => {
    getApiData(`v1/mapping/`).then((res) => {
      if (res.status) {
        setData(res.data);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const getCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude);
          setLon(position.coords.longitude);
        },
        (error) => {
          console.log(error);
          toast.error("Location permission issue");
        }
      );
    }
  };

  return (
    <div>
      <Map
        google={google}
        zoom={12}
        onClick={onMapClicked}
        initialCenter={{ lat: lat, lng: lon }}
      >
        {data &&
          data.length &&
          data.map((item) => {
            return (
              <Marker
                title={"The marker`s title will appear as a tooltip."}
                data={item}
                onClick={onMarkerClick}
                position={{ lat: item.lattitude, lng: item.longitude }}
                icon={{
                  url: `/media/${item.type}.png`,
                  anchor: new google.maps.Point(32, 32),
                  scaledSize: new google.maps.Size(64, 64),
                }}
              />
            );
          })}

        <InfoWindow
          marker={activeMarker}
          onClose={() => {
            setActiveMarker(null);
            setShowingInfoWindow(false);
          }}
          visible={showingInfoWindow}
        >
          <div>
            <img
              src={selectedPlace.profile_picture}
              className="h-50px d-flex ml-auto mr-auto"
            />
            <p className="mb-1 mt-2">
              Username: <b>{selectedPlace.username}</b>
            </p>
            <p className="mb-1">
              Phone:{" "}
              <b>
                <a href={`tel:${selectedPlace.phone_number}`}>
                  {selectedPlace.phone_number}
                </a>
              </b>
            </p>
            <p className="mb-1">
              Area: <b>{selectedPlace.area_name}</b>
            </p>
            {selectedPlace.type === "chhotu" && (
              <p className="mb-1">
                Tapri: <b>{selectedPlace.tapri_name}</b>
              </p>
            )}
            <p className="mb-1">
              Commission: <b>₹{selectedPlace.commission ?? 0}</b>
            </p>
            <p className="mt-2 mb-0 text-center">
              {timeSince(selectedPlace.last_updated)}
            </p>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo",
})(MapContainer);
