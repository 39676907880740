import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  CardBody,
  CardHeader,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import { DeleteModal, Loader, QrCodeModal } from "../../../../components";
import { getParams, PER_PAGE, sizePerPageList } from "../../../../config";
import { getApiData } from "../../../../Services";
import { UtensilModal } from "../Modal";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [utensilModal, setUtensilModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [qrCodeModal, setQrCodeModal] = useState({
    value: "",
    status: false,
  });

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`v1/utensils/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const changeIsDefault = async (id) => {
    // setLoading(true);
    return await getApiData(`v1/utensils/${id}/`, "delete").then((res) => {
      if (!res.status) {
        toast.error(res.message);
      }
    });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "code",
      text: "Code",
      headerStyle: () => {
        return { minWidth: "150px" };
      },
      formatter: (_, row) => (
        <span>
          {row.code}
          <i
            className="flaticon-eye ml-2 cursor-pointer"
            onClick={() => {
              setQrCodeModal({
                value: row.code,
                status: true,
              });
            }}
          />
        </span>
      ),
    },
    {
      dataField: "size",
      text: "Size",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "type",
      text: "Type",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "capacity",
      text: "Capacity",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "tapri_name",
      text: "Tapri",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "name",
      text: "Current Owner",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
      formatter: (_, row) => (
        <div>
          <span>{row.name}</span>
          <br />
          <span className="timeStampColor">({row.owner_type})</span>
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => {
        return { minWidth: "100px" };
      },
      formatter: (_, row) => (
        <span class="switch switch-sm">
          <label>
            <input
              type="checkbox"
              onChange={(e) => {
                toast.promise(changeIsDefault(row.id, e.target.checked), {
                  loading: "Updating...",
                  success: "Success!",
                  error: "Failed.",
                });
              }}
              defaultChecked={row.status}
            />
            <span></span>
          </label>
        </span>
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (_, row) => (
        <>
          <button
            className="btn btn-icon btn-light btn-hover-secondary btn-sm"
            onClick={() => {
              setUtensilModal(true);
              setSelectedRow(row);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-secondary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </button>
          <> </>

          {/* <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setDeleteModal(true);
            }}
            // disabled={true}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button> */}
        </>
      ),
      // classes: 'text-right pr-0',
      // headerClasses: 'text-right pr-3',
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };
  return (
    <>
      {" "}
      <QrCodeModal
        data={qrCodeModal}
        onHide={() => setQrCodeModal({ value: "", status: "" })}
      />
      <CardHeader className="px-0 mt-4 custom-card-header">
        <div className="row col-sm-12 pr-0 ">
          <div>
            <a
              className="btn btn-primary float-right"
              onClick={() => {
                setSelectedRow({});
                setUtensilModal(true);
              }}
            >
              Add New Utensil
            </a>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <DeleteModal
          show={deleteModal}
          api="v1/utensils"
          title="Delete Utensil"
          message="Are you sure to permanently delete this Utensil?"
          onHide={() => setDeleteModal(!deleteModal)}
          id={selectedRow.id}
          onSuccess={(message) => {
            toast.success(message);
            getTableRecords();
          }}
          onFailed={(message) => {
            toast.error(message);
          }}
        />
        <UtensilModal
          show={utensilModal}
          productList={props.productList}
          tapriList={props.tapriList}
          onHide={() => setUtensilModal(!utensilModal)}
          data={selectedRow}
          onSuccess={(message) => {
            setUtensilModal(false);
            toast.success(message);
            getTableRecords();
          }}
          onError={(message) => {
            toast.error(message);
          }}
        />
        {loading ? (
          <Loader />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    onTableChange={() => {}}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    id="userTable"
                    bootstrap4
                    keyField="id"
                    data={data === null ? [] : data}
                    columns={columns}
                    // cellEdit={cellEditFactory({
                    //   mode: "click",
                    //   blurToSave: true,
                    // })}
                    noDataIndication={() => {
                      return "No Data to Display";
                    }}
                    defaultSorted={[{ dataField: "id", order: "asc" }]}
                    {...paginationTableProps}
                  >
                    {/* <PleaseWaitMessage entities={data} /> */}
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )}
      </CardBody>
    </>
  );
}
