import React from "react";
import { Modal } from "react-bootstrap";

export default function ({ show, onHide, onSuccess, onError }) {
  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add Wastage{" "}
          <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Batch:</span> 1997
          </span>
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3">
        <div className="row">
          <div className="col-lg-12 mb-5 mb-md-5">
            <label htmlFor className="font-weight-bolder">
              Product
            </label>
            <select
              name
              className="form-control form-control-lg form-control-solid "
              id
            >
              <option value disabled selected>
                Select Product
              </option>
              <option value />
            </select>
          </div>
          <div className="col-lg-12 mb-5 mb-md-5">
            <label htmlFor className="font-weight-bolder">
              Container
            </label>
            <select
              name
              className="form-control form-control-lg form-control-solid "
              id
            >
              <option value disabled selected>
                Select Container
              </option>
              <option value />
            </select>
          </div>
          <div className="col-lg-12 mb-5 mb-md-5">
            <label htmlFor className="font-weight-bolder">
              Quantity
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid "
              placeholder="Enter Quantity"
            />
          </div>
          <div className="col-md-12 mb-5 mb-md-5">
            <label htmlFor className="font-weight-bolder">
              Select Reason
            </label>
            <select
              name
              className="form-control form-control-lg form-control-solid "
              id
            >
              <option value disabled selected>
                Select Reason
              </option>
              <option value />
            </select>
          </div>
          <div className="col-md-12 ">
            <label htmlFor className="font-weight-bolder">
              Add Remarks
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid "
              id
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        <button type="button" className="btn btn-danger font-weight-bold px-10">
          Submit
        </button>
      </div>
    </Modal>
  );
}
