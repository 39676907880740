import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { getApiData } from "../../../Services";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
export default function() {
  const history = useHistory();

  const [productList, setProductList] = useState([]);
  const [tapriList, setTapriList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    type: "",
    tapri: "",
    status_: "True",
  });

  useEffect(() => {
    getProductList();
    getTapriList();
  }, []);

  const getProductList = () => {
    getApiData("v1/products/").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.name, value: item.name }];
        });
        setProductList(array);
      }
    });
  };

  const getTapriList = () => {
    getApiData("v1/tapris/?limit=1000").then((res) => {
      if (res.status) {
        let tapri = [];
        res.data.map((item) => {
          tapri = [...tapri, { label: item.name, value: item.id }];
        });
        setTapriList(tapri);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  return (
    <>
      <div>
        <Card className="card-spaces">
          <CardBody className="p-4">
            <TableFilter
              status={filters.status_}
              productList={productList}
              tapriList={tapriList}
              setSearchString={(value) => {
                let obj = { ...filters };
                obj.search = value;
                setFilters(obj);
              }}
              setTypeValue={(value) => {
                let obj = { ...filters };
                obj.type = value;
                setFilters(obj);
              }}
              setTapriValue={(value) => {
                let obj = { ...filters };
                obj.tapri = value;
                setFilters(obj);
              }}
              setStatusFilter={(value) => {
                let obj = { ...filters };
                obj.status_ = value;
                setFilters(obj);
              }}
              onClearFilter={() => {
                setFilters({
                  search: "",
                  type: "",
                  tapri: "",
                  status_: "True",
                });
              }}
            />
          </CardBody>
        </Card>
        <Card>
          <Table
            filters={filters}
            productList={productList}
            // stateList={stateList}
            tapriList={tapriList}
          />
        </Card>
      </div>
    </>
  );
}
