import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { DeleteModal, Loader } from "../../../components";
import { getParams, PER_PAGE, sizePerPageList } from "../../../config";
import { getApiData } from "../../../Services";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`v1/buildings/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const changeIsDefault = async (id, status) => {
    // setLoading(true);
    return await getApiData(`v1/buildings/${id}/`, "put", {
      enabled: status ? 1 : 0,
    }).then((res) => {
      if (!res.status) {
        toast.error(res.message);
      } else {
        getTableRecords();
      }
    });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "name",
      text: "Building Name",
      headerStyle: () => {
        return { minWidth: "150px" };
      },
    },
    {
      dataField: "address",
      text: "Building Address",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "state",
      text: "State",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "city",
      text: "City",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "area_name",
      text: "Area",
      headerStyle: () => {
        return { minWidth: "130px" };
      },
    },
    {
      dataField: "tapri_name",
      text: "Tapri",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => {
        return { minWidth: "100px" };
      },
      formatter: (_, row) => (
        <span class="switch switch-sm">
          <label>
            <input
              type="checkbox"
              onChange={(e) => {
                toast.promise(changeIsDefault(row.id, e.target.checked), {
                  loading: "Updating...",
                  success: "Success!",
                  error: "Failed.",
                });
              }}
              checked={row.enabled}
            />
            <span></span>
          </label>
        </span>
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (_, row) => (
        <>
          <Link
            to={{
              pathname: "/master/building/edit",
              state: row,
            }}
            className="btn btn-icon btn-light btn-hover-secondary btn-sm"
            // onClick={() => {
            //   setSelectedRow(row);
            // }}
          >
            <span className="svg-icon svg-icon-md svg-icon-secondary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </Link>
          <> </>

          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setDeleteModal(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </>
      ),
      // classes: 'text-right pr-0',
      // headerClasses: 'text-right pr-3',
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };
  return (
    <>
      <DeleteModal
        show={deleteModal}
        api="v1/buildings"
        title="Delete Building"
        message="Are you sure to permanently delete this Building?"
        onHide={() => setDeleteModal(!deleteModal)}
        id={selectedRow.id}
        onSuccess={(message) => {
          getTableRecords();
          toast.success(message);
        }}
        onFailed={(message) => {
          toast.error(message);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination isLoading={false} paginationProps={paginationProps}>
                <BootstrapTable
                  remote
                  onTableChange={() => {}}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  id="userTable"
                  bootstrap4
                  keyField="id"
                  data={data === null ? [] : data}
                  columns={columns}
                  // cellEdit={cellEditFactory({
                  //   mode: "click",
                  //   blurToSave: true,
                  // })}
                  noDataIndication={() => {
                    return "No Data to Display";
                  }}
                  defaultSorted={[{ dataField: "id", order: "asc" }]}
                  {...paginationTableProps}
                >
                  {/* <PleaseWaitMessage entities={data} /> */}
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      )}
    </>
  );
}
