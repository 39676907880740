import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { STRIPE_PUBLISHABLE_KEY, validEmail } from "../../../config";
import { getApiData } from "../../../Services";
// const recaptchaRef = React.createRef()

function Registration(props) {
  const history = useHistory();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [refCode, setRefCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registerSending, setRegisterSending] = useState(false);
  const [paymentSending, setPaymentSending] = useState(false);
  const [checkCondition, setCheckCondition] = useState("");
  // const [registerCaptcha, setRegisterCaptcha] = useState('')
  const [registerStatus, setRegisterStatus] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [userObject, setUserObject] = useState(null);

  useEffect(() => {
    // console.log('props')
    // console.log(props)
    if (history.location.pathname === "/sign-up") {
      // console.log(history)
      var url_string = window.location.href;
      var url = new URL(url_string);
      var type = url.searchParams.get("type");
      var plan_type = url.searchParams.get("plan_type");
      var ref_code = url.searchParams.get("ref_code");

      if (ref_code) {
        setRefCode(ref_code);
      }

      if (type && plan_type) {
        getApiData(`plans?name=${plan_type}`, "get", "", false).then((res) => {
          if (!res.data) {
            window.location.href = "sign-in";
          } else {
            setPlanDetails(res.data);
          }
        });
      }
    }
  }, []);

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    setRegisterStatus(null);
    if (!validEmail(email)) {
      setRegisterStatus("Enter valid email");
    } else if (password !== confirmPassword) {
      setRegisterStatus("Confirm password must be same");
    } else {
      setRegisterSending(true);

      const data = {
        name: fullName,
        email: email,
        password: password,
        reference_referral_code: refCode,
        sign_up_type: "free",
      };

      // window.grecaptcha.enterprise.ready(function () {
      //   window.grecaptcha.enterprise
      //     .execute(SITE_KEY, {
      //       action: 'registration',
      //     })
      //     .then(function (token) {
      //       console.log(token)
      getApiData("sign-up", "post", data, false).then((res) => {
        // console.log(res)

        if (res.status) {
          // alert('suc')

          console.log(res.data);
          toast.success(res.message);
          if (planDetails) {
            setUserObject(res.data);
            setRegisterSending(false);
          } else {
            props.login(res.data);
            setRegisterSending(false);

            // alert('redirect dashboard')
          }

          // props.login(res.data)
        } else {
          setRegisterSending(false);
          setRegisterStatus(res.data[Object.keys(res.data)[0]]);
        }
      });
      //     })
      // })
    }
  };

  const onTokenReceive = (token) => {
    // console.log('api call')
    console.log(token);
    if (token.id) {
      const data = {
        user_id: userObject.user_data.id,
        stripe_token: token.id,
        plan_name: planDetails.name,
      };
      setPaymentSending(true);

      getApiData("user/payment", "post", data, false).then((res) => {
        setPaymentSending(false);
        if (res.status) {
          toast.success(res.message);
          props.login(userObject);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <form
      className="form fv-plugins-bootstrap fv-plugins-framework"
      // noValidate="novalidate"
      id="kt_login_signup_form"
    >
      {/*begin::Title*/}
      <div className="pb-7 pt-lg-0 pt-5">
        <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Sign Up
        </h3>
        <p className="text-muted font-weight-bold font-size-h4">
          Enter your details to create your account
        </p>
      </div>
      {registerStatus && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{registerStatus}</div>
        </div>
      )}
      {/*end::Title*/}
      {/*begin::Form group*/}
      <div className="form-group">
        <input
          type="text"
          placeholder="Fullname"
          className={`form-control font-size-h6  h-auto py-6 px-6 `}
          name="fullname"
          onChange={(e) => {
            setFullName(e.target.value);
          }}
        />
      </div>
      {/*end::Form group*/}
      {/*begin::Form group*/}
      <div className="form-group">
        <input
          className="form-control  h-auto py-6 px-6 rounded-lg font-size-h6"
          type="email"
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      {/*end::Form group*/}
      {/*begin::Form group*/}
      <div className="form-group">
        <input
          className="form-control  h-auto py-6 px-6 rounded-lg font-size-h6"
          type="password"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      {/*end::Form group*/}
      {/*begin::Form group*/}
      <div className="form-group">
        <input
          className="form-control  h-auto py-6 px-6 rounded-lg font-size-h6"
          type="password"
          placeholder="Confirm password"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <input
          className="form-control  h-auto py-6 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Referral Code (optional)"
          value={refCode}
          onChange={(e) => {
            setRefCode(e.target.value);
          }}
        />
      </div>
      {/*end::Form group*/}
      {/*begin::Form group*/}
      <div className="form-group">
        <label className="checkbox mb-0">
          <input
            type="checkbox"
            name="agree"
            onChange={(e) => {
              setCheckCondition(e.target.checked);
            }}
          />
          <span />
          <div className="ml-2">
            I Agree the
            <a href="https://aalacart.com/terms-condition.html" target="_blank">
              {" "}
              terms and conditions
            </a>
            .
          </div>
        </label>
      </div>
      {/* <ReCAPTCHA
        style={{ display: 'inline-block' }}
        ref={recaptchaRef}
        sitekey={SITE_KEY}
        onChange={(e) => {
          setRegisterCaptcha(e)
        }}
      /> */}

      <div className="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          type="submit"
          disabled={
            fullName &&
            email &&
            password &&
            checkCondition &&
            confirmPassword &&
            !registerSending
              ? false
              : true
          }
          onClick={handleRegisterSubmit}
          className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
        >
          Submit
          {registerSending && (
            <span className="ml-3 spinner spinner-white"></span>
          )}
        </button>
        <button
          type="button"
          id="kt_login_signup_cancel"
          onClick={() => {
            history.push("/sign-in");
          }}
          className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
        >
          Cancel
        </button>
        {planDetails && userObject && (
          <StripeCheckout
            token={onTokenReceive}
            stripeKey={STRIPE_PUBLISHABLE_KEY}
            amount={parseInt(planDetails.amount) * 100}
            name={planDetails.label}
            email={email}
            image="https://app.aalacart.com/media/logos/favicon.png"
          >
            <button
              type="button"
              className="btn btn-success ml-4 font-weight-bolder font-size-h6 px-8 py-4 my-3"
              disabled={paymentSending}
            >
              Pay ${planDetails.amount} now
              {paymentSending && (
                <span className="ml-3 spinner spinner-white"></span>
              )}
            </button>
          </StripeCheckout>
        )}
      </div>
      {/*end::Form group*/}
    </form>
  );
}

export default Registration;
