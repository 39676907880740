import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";

export default function({ data, show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [credits, setCredits] = useState("");

  useEffect(() => {
    if (data.negative_upto) {
      setCredits(data.negative_upto);
    }
  }, [data]);

  const onSubmit = () => {
    setSending(true);
    const body = {
      user_id: data.id,
      negative_upto: parseInt(credits),
    };
    getApiData(`v1/credit/change`, "post", body).then((res) => {
      setSending(false);
      if (res.status) {
        onSuccess(res.message);
        onHide();
      } else {
        if (typeof res.message === "string") {
          onError(res.message);
        } else {
          onError("Something went wrong!");
        }
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Change Limit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="form-group is-invalid mb-0">
            <label>Limit</label>
            <input
              type="number"
              className="form-control"
              value={credits}
              onChange={(e) => {
                setCredits(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && credits > 0) {
                  onSubmit();
                }
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            disabled={credits && !sending ? false : true}
            className="btn btn-danger btn-elevate"
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
