// import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as Yup from "yup";
// import { login } from '../_redux/authCrud'
import { API_URL } from "../../../config";
import { fetchToken } from "../../../config/firebase";
import * as auth from "../_redux/authRedux";
// import ReCAPTCHA from 'react-google-recaptcha-enterprise'
// import { getApiData } from '../../../Services'
// import toast, { Toaster } from 'react-hot-toast'
import Registration from "./Registration";
// import { getApiData } from '../../../Services'

const initialValues = {
  email: "admin",
  password: "admin@123",
};

// const recaptchaRef = React.createRef();

function Login(props) {
  useEffect(() => {
    (function(d) {
      var a = d.createElement("script");
      a.type = "text/javascript";
      a.async = true;
      a.id = "loginJs";
      a.src = "/js/login-general.js";
      d.getElementById("script-root").appendChild(a);
    })(document);
  }, []);
  const { intl } = props;

  const [loading, setLoading] = useState(false);
  // const [isValid, setIsValid] = useState(true)
  const [status, setStatus] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      // .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      // if (!isValid) {
      //   setStatus('Please Verify captcha')
      //   return false
      // }
      setStatus(null);

      enableLoading();

      // window.grecaptcha.enterprise.ready(function () {
      //   window.grecaptcha.enterprise
      //     .execute(SITE_KEY, {
      //       action: "login",
      //     })
      //     .then(function (token) {
      // console.log(token);
      fetch(`${API_URL}v1/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: values.email,
          password: values.password,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res)
          if (res.status) {
            const userObj = {
              user_data: {
                name: res.data.is_admin
                  ? "admin"
                  : res.data.first_name + " " + res.data.last_name,
                user_type: res.data.is_admin ? "admin" : "tapri",
                email: res.data.is_admin ? values.email : res.data.email,
              },
              auth_token: res.data.token,
            };

            fetchToken(res.data.token);

            props.login(userObj);
          } else {
            setSubmitting(false);
            disableLoading();
            setStatus(res.message);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          disableLoading();
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
        });
      //     });
      // });
    },
  });

  return (
    <>
      <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        {/*begin::Content body*/}
        <div className="d-flex flex-column-fluid flex-center">
          {/*begin::Signin*/}
          <div className="login-form login-signin">
            {/*begin::Form*/}
            <form
              // onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
              // noValidate="novalidate"
              id="kt_login_signin_form"
            >
              <div className="pb-7 pt-lg-0 pt-5">
                <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  Sign In to Redose
                </h3>
              </div>
              {status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              )}
              <div className="form-group">
                <label className="font-size-h6 font-weight-bolder text-dark">
                  Email
                </label>
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control font-size-h6  h-auto py-6 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Form group*/}
              {/*begin::Form group*/}
              <div className="form-group">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                    Password
                  </label>
                  <a
                    href="javascript:;"
                    className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                  >
                    Forgot Password ?
                  </a>
                </div>
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control font-size-h6  h-auto py-6 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              {/*end::Form group*/}
              {/*begin::Action*/}
              {/* <ReCAPTCHA
                style={{ display: 'inline-block' }}
                ref={recaptchaRef}
                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                sitekey={SITE_KEY}
                onChange={(e) => {
                  setStatus(null)
                  setIsValid(true)
                }}
              /> */}
              <div className="pb-lg-0 pb-5">
                <button
                  // id="kt_login_signin_submit"
                  type="submit"
                  onClick={formik.handleSubmit}
                  disabled={
                    formik.getFieldProps("email").value !== "" &&
                    formik.getFieldProps("email").password !== "" &&
                    !formik.isSubmitting
                      ? false
                      : true
                  }
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3 mr-3`}
                >
                  <span>Sign In</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
              {/*end::Action*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Signin*/}
          {/*begin::Signup*/}
          <div className="login-form login-signup">
            <Registration {...props} />
          </div>
          {/*end::Signup*/}
          {/*begin::Forgot*/}
          <div className="login-form login-forgot">
            {/*begin::Form*/}
            <form
              className="form"
              noValidate="novalidate"
              id="kt_login_forgot_form"
            >
              {/*begin::Title*/}
              <div className="pb-7 pt-lg-0 pt-5">
                <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  Forgotten Password ?
                </h3>
                <p className="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              {/*end::Title*/}
              {/*begin::Form group*/}
              <div className="form-group">
                <input
                  className="form-control  h-auto py-6 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                />
              </div>
              {/*end::Form group*/}
              {/*begin::Form group*/}
              <div className="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                >
                  Cancel
                </button>
              </div>
              {/*end::Form group*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Forgot*/}
        </div>
        {/*end::Content body*/}
        {/*begin::Content footer*/}
        <div className="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
          <div className="text-dark-50 font-size-lg font-weight-bolder mr-10">
            <span className="mr-1">2022©</span>
            <a
              // href="http://keenthemes.com/metronic"
              // target="_blank"
              className="text-dark-75 text-hover-primary"
            >
              Redose
            </a>
          </div>
        </div>
        {/*end::Content footer*/}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
