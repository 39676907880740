import React, { useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
export default function() {
  // const history = useHistory();

  // const [productList, setProductList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    type: "",
  });

  return (
    <>
      <div>
        <Card className="card-spaces">
          <CardBody className="p-4">
            <TableFilter
              setSearchString={(value) => {
                let obj = { ...filters };
                obj.search = value;
                setFilters(obj);
              }}
              setTypeValue={(value) => {
                let obj = { ...filters };
                obj.type = value;
                setFilters(obj);
              }}
              onClearFilter={() => {
                setFilters({
                  search: "",
                  type: "",
                });
              }}
            />
          </CardBody>
        </Card>
        <Card>
          <Table
            filters={filters}
            // stateList={stateList}
            // managerList={managerList}
          />
        </Card>
      </div>
    </>
  );
}
