import React, { useState } from "react";

export default function(props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  return (
    <>
      <div className="row">
        {/* <div className="col-md-3">
          <GeneralDropdown
            list={[
              { label: "Admin", value: "3" },
              { label: "User", value: "1" },
              { label: "Tapri", value: "2" },
              { label: "Building Acquisition", value: "4" },
            ]}
            handleChange={(obj) => {
              console.log(obj);
            }}
          />
          <small className="form-text text-muted">
            Filter by <b>Type</b>
          </small>
        </div> */}
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value.length > 2) {
                setIsSearching(true);
                props.setSearchString(e.target.value);
              } else {
                if (isSearching) {
                  props.setSearchString("");
                  setIsSearching(false);
                }
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsSearching(true);
                props.setSearchString(search);
              }
            }}
          />
          <small className="form-text text-muted">
            <b>Search</b> in all fields
          </small>
        </div>
        <div className="col-lg-1 ">
          <button
            className="btn btn-block btn-light-danger px-6 font-weight-bolder"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </>
  );
}
