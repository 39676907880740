import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import { GeneralDropdown } from "../../../../components";
import { useHistory } from "react-router-dom";

export default function({
  show,
  onHide,
  onSuccess,
  onError,
  stateList,
  managerList,
  data,
}) {
  const history = useHistory();

  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  // const [managerList, setmanagerList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");

  useEffect(() => {
    if (data.id) {
      setName(data.name);
      setSelectedManager(data.manager);
      setAddress(data.address);
      if (data.location && data.location.includes(",")) {
        let location = data.location.split(",");
        setLat(location[0].trim());
        setLon(location[1].trim());
      }
    } else {
      setName("");
    }
  }, [data]);

  useEffect(() => {
    if (stateList.length && data.state) {
      stateList.map((item) => {
        if (item.value === data.state) {
          console.log(item);
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList, data]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (cityList.length && data.city) {
      cityList.map((item) => {
        if (item.value === data.city) {
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList, data]);

  useEffect(() => {
    if (areaList.length && data.area) {
      areaList.map((item) => {
        if (item.value === data.area) {
          setSelectedArea(item);
          return;
        }
      });
    }
  }, [areaList, data]);

  const onSubmit = () => {
    setSending(true);
    const latLong = `${lat},${lon}`;

    const body = {
      name: name,
      area: selectedArea.value,
      manager: selectedManager.value,
      location: latLong,
      address: address,
    };
    let method = data.id ? "put" : "post";
    let param = data.id ? data.id + "/" : "";

    getApiData(`v1/tapris/${param}`, method, body).then((res) => {
      setSending(false);
      if (res.status) {
        onHide();
        onSuccess(res.message, res.data);
      } else {
        if (typeof res.message === "string") {
          onError(res.message);
        } else {
          onError("Something went wrong!");
        }
      }
    });
  };
  const getCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude.toFixed(4));
          setLon(position.coords.longitude.toFixed(4));
        },
        (error) => {
          console.log(error);
          alert("please allow location");
        }
      );
    }
  };

  useEffect(() => {
    if (selectedCity && selectedCity.value) {
      getAreaByCity(selectedCity.value);
    }
  }, [selectedCity]);

  const getAreaByCity = (city) => {
    getApiData(`v1/areas/?city=${city}`).then((res) => {
      if (res.status) {
        let area = [];
        res.data.map((item) => {
          area = [...area, { label: item.name, value: item.id }];
        });
        setAreaList(area);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Tapri</Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Tapri Name</label>
              <input
                className="form-control"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-12 row">
            <div className="col-md-6 ">
              <div className="form-group">
                <div className="d-flex">
                  <label className="fw-bolder mb-1">Latitude</label>
                  <a
                    className="ml-auto"
                    onClick={() => {
                      getCurrentLocation();
                    }}
                  >
                    <i
                      className="fa fa-crosshairs fs-7 mr-2"
                      style={{ color: "black" }}
                    />
                    Get Current
                  </a>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={lat}
                  onChange={(e) => {
                    setLat(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="fw-bolder mb-1">Longitude</label>
                <input
                  type="number"
                  className="form-control"
                  value={lon}
                  onChange={(e) => {
                    setLon(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Tapri Address</label>
              <textarea
                className="form-control"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-12 row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Select State</label>
                <GeneralDropdown
                  selectedOption={data ? data.state : null}
                  list={stateList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedState(obj);
                    setSelectedCity(null);
                    setSelectedArea(null);
                    if (obj && obj.city && obj.city.length) {
                      let city = [];
                      obj.city.map((item) => {
                        city = [...city, { label: item, value: item }];
                      });
                      setCityList(city);
                    }
                    //   props.onStateSelect(obj);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Select City</label>
                <GeneralDropdown
                  selectedOption={data ? data.city : null}
                  list={cityList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedArea(null);
                    setSelectedCity(obj);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Select Area</label>
                <GeneralDropdown
                  selectedOption={data ? data.area : null}
                  list={areaList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedArea(obj);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Select Manager</label>
                <GeneralDropdown
                  selectedOption={selectedManager}
                  list={managerList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedManager(obj);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary px-5"
          onClick={onSubmit}
          disabled={
            name && selectedArea && address && lat && lon && !sending
              ? false
              : true
          }
        >
          Submit
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
