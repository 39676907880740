import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../Services";

export default function({
  id,
  show,
  onHide,
  onSuccess,
  onFailed,
  api,
  title = "Delete Record",
  message = "Are you sure to permanently delete this Record?",
}) {
  const [sending, setSending] = useState(false);

  const deleteRecord = () => {
    setSending(true);
    getApiData(`${api}/${id}/`, "delete").then((res) => {
      setSending(false);
      if (res.status) {
        onSuccess(res.message);
        onHide();
      } else {
        onFailed(res.message);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{message}</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteRecord}
            disabled={sending}
            className="btn btn-danger btn-elevate"
          >
            Delete
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
