import React, { useState, useEffect } from "react";
import {
  IngredientModal,
  OutputQtyModal,
  CancelBatchModal,
  BatchModal,
  FillUtensilModal,
  ShowUtensilModal,
} from "./../modals";
import toast from "react-hot-toast";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "./../../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../../Services";
import Pagination from "react-js-pagination";
import { Loader } from "../../../../components";
import { getParams, PER_PAGE } from "../../../../config";
function Table({ updateShowing, filters }) {
  const { showing } = useSelector((state) => state.auth);
  const [batchModal, setBatchModal] = useState(false);
  const [ingredientModal, setIngredientModal] = useState(false);
  const [fillUtensilModal, setFillUtensilModal] = useState(false);
  const [showUtensilModal, setShowUtensilModal] = useState(false);
  const [ingredientList, setIngredientList] = useState([]);
  const [outputQtyModal, setOutputQtyModal] = useState(false);
  const [cancelBatchModal, setCancelBatchModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [outputQtyValue, setOutputQtyValue] = useState(0.0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    if (showing) {
      setBatchModal(true);
    }
  }, [showing]);

  useEffect(() => {
    setLoading(true);
    getRecords();
  }, [activePage, filters]);

  useEffect(() => {
    getIngredient();
    getProductList();
  }, []);

  const getRecords = () => {
    const params = getParams(filters, activePage, PER_PAGE);

    getApiData(`tapri/v1/batch/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (activePage > 1)
              value = PER_PAGE * activePage - PER_PAGE + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  const getProductList = () => {
    getApiData("v1/products/").then((res) => {
      if (res.status) {
        let list = [];
        res.data.map((item) => {
          list = [...list, { label: item.name, value: item.id }];
        });
        setProductList(list);
      }
    });
  };

  const getIngredient = () => {
    getApiData("v1/ingredients/").then((res) => {
      if (res.status) {
        let list = [];
        res.data.map((item) => {
          list = [...list, { label: item.name, value: item.id, data: item }];
        });
        setIngredientList(list);
      }
    });
  };

  return (
    <div className="row cus-row">
      <BatchModal
        list={productList}
        show={batchModal}
        onHide={() => {
          updateShowing(null);
          setBatchModal(!batchModal);
        }}
        onSuccess={(message) => {
          getRecords();
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <IngredientModal
        show={ingredientModal}
        list={ingredientList}
        data={selectedRow}
        onHide={() => setIngredientModal(false)}
        onSuccess={(message) => {
          getRecords();
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
        status={
          filters.status_ === "running"
            ? outputQtyValue === 0.0
              ? "running"
              : "completed"
            : "completed"
        }
      />
      <OutputQtyModal
        show={outputQtyModal}
        data={selectedRow}
        onHide={() => setOutputQtyModal(!outputQtyModal)}
        onSuccess={(message) => {
          getRecords();
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
        status={filters.status_}
      />
      <FillUtensilModal
        show={fillUtensilModal}
        data={selectedRow}
        onHide={() => setFillUtensilModal(!fillUtensilModal)}
        onSuccess={(message) => {
          getRecords();
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <ShowUtensilModal
        show={showUtensilModal}
        data={selectedRow}
        onHide={() => setShowUtensilModal(!showUtensilModal)}
      />
      <CancelBatchModal
        data={selectedRow}
        show={cancelBatchModal}
        onHide={() => setCancelBatchModal(!cancelBatchModal)}
        onSuccess={(message) => {
          getRecords();
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <div className="col-lg-12">
        {/*begin::Mixed Widget 14*/}
        <div className="card card-custom   gutter-b card-stretch" style={{}}>
          {/*begin::Body*/}
          <div className="card-body px-0 table-responsive">
            {loading ? (
              <div className="p-4">
                <Loader />
              </div>
            ) : (
              <>
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th className="pl-3">
                        <span className="text-dark-75">#</span>
                      </th>
                      <th>Batch No</th>
                      <th>Start Time</th>
                      <th>Product</th>
                      <th>Manage</th>
                      {filters.status_ === "cancelled" && <th>Reason</th>}
                      <th>Status</th>
                      {filters.status_ === "running" && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length ? (
                      data.map((item) => {
                        return (
                          <tr>
                            <td className="pl-3">
                              <span className="font-weight-bolder font-size-sm">
                                {item.sr_no}
                              </span>
                            </td>
                            <td>
                              <span className="font-weight-bolder font-size-sm">
                                {item.batch_id ?? "-"}
                              </span>
                            </td>
                            <td>
                              <span className="font-weight-bolder font-size-sm">
                                {new Date(item.created_at).toLocaleDateString()}{" "}
                                <br />
                                {new Date(item.created_at).toLocaleTimeString()}
                              </span>
                            </td>
                            <td>
                              <span className="text-dark-75 font-weight-bolder font-size-md">
                                {item.product_name}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex">
                                <button
                                  className="btn btn-sm btn-light-danger font-weight-bolder font-size-sm"
                                  onClick={() => {
                                    setOutputQtyValue(item.output_qty);
                                    setSelectedRow(item);
                                    setIngredientModal(true);
                                  }}
                                >
                                  {filters.status_ === "running"
                                    ? item.output_qty === 0.0
                                      ? "Add "
                                      : "View "
                                    : "View "}
                                  Ingredients
                                </button>
                                <button
                                  className="btn btn-sm btn-light-danger font-weight-bolder font-size-sm mx-2"
                                  disabled={
                                    item.ingredients.length ? false : true
                                  }
                                  onClick={() => {
                                    setSelectedRow(item);
                                    setOutputQtyModal(true);
                                  }}
                                >
                                  Output Qty
                                </button>
                                {filters.status_ === "running" && (
                                  <button
                                    className="btn btn-sm btn-light-danger font-weight-bolder font-size-sm"
                                    disabled={
                                      item.output_qty > 0 ? false : true
                                    }
                                    onClick={() => {
                                      setSelectedRow(item);
                                      setFillUtensilModal(true);
                                    }}
                                  >
                                    Fill Utensils
                                  </button>
                                )}
                                {filters.status_ === "completed" && (
                                  <button
                                    className="btn btn-sm btn-light-danger font-weight-bolder font-size-sm"
                                    onClick={() => {
                                      setSelectedRow(item);
                                      setShowUtensilModal(true);
                                    }}
                                  >
                                    Utensils
                                  </button>
                                )}
                              </div>
                            </td>
                            {filters.status_ === "cancelled" && (
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.reason}
                                </span>
                              </td>
                            )}
                            <td>
                              <span
                                className={`label text-capitalize label-lg label-light-${
                                  item.status === "running"
                                    ? "primary"
                                    : item.status === "cancelled"
                                    ? "danger"
                                    : "success"
                                } label-inline font-weight-bolder`}
                              >
                                {item.status}
                              </span>
                            </td>
                            {filters.status_ === "running" && (
                              <td className="text-left">
                                <a
                                  onClick={() => {
                                    setSelectedRow(item);
                                    setCancelBatchModal(true);
                                  }}
                                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                >
                                  <i
                                    className="flaticon2-delete text-danger"
                                    style={{ fontSize: "11px" }}
                                  />
                                </a>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="pl-3">No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {totalRecords > 0 && (
                  <div className="mt-2 float-right mr-3">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={PER_PAGE}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={5}
                      itemClass="d-flex justify-content-between align-items-center flex-wrap"
                      linkClass="btn btn-icon btn-sm border-0 btn-light  btn-hover-primary mr-2 my-1"
                      activeLinkClass="btn btn-icon btn-sm border-0 btn-light  btn-hover-primary mr-2 my-1 active"
                      onChange={(pageNumber) => {
                        setActivePage(pageNumber);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Mixed Widget 14*/}
      </div>
    </div>
  );
}
export default injectIntl(connect(null, auth.actions)(Table));
