import React from "react";
import Filter from "./Filter";
import Items from "./Items";
import Products from "./Products";
export default function () {
  return (
    <div>
      <Filter />
      <div className="mt-7">
        <Items />
        <Products />
      </div>
    </div>
  );
}
