import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";

export default function({ show, onHide, data, onSuccess, onError }) {
  const [actualQty, setActualQty] = useState("");
  const [selected, setSelected] = useState("container");
  const [remark, setRemark] = useState("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    setActualQty("");
  }, [data]);

  const onSubmit = () => {
    if (actualQty >= 0 && selected) {
      setSending(true);
      const body = {
        type: "ready_to_wash",
        quantity: actualQty,
        inventory_destination: selected,
        code: data.code,
      };

      if (remark) {
        body.remarks = remark;
      }

      getApiData(`tapri/v1/utensil/status/change`, "post", body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message);
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError(Object.values(res.message)[0]);
          }
        }
      });
    }
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Ready to Wash
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3">
        <div className="row">
          <div className="col-md-12 mb-8">
            <span className="label label-lg label-light-danger label-inline font-weight-bolder mr-2">
              <span className="text-dark mr-1">Thermos:</span> {data.code}
            </span>
            <span className="label label-lg label-light label-inline font-weight-bolder mr-2">
              <span className="text-dark mr-1">{data.product}</span>
            </span>
            <button
              className="label label-lg label-light label-inline font-weight-bolder"
              onClick={() => {
                setActualQty(data.current_level);
              }}
            >
              Approx Qty:{" "}
              <span className="text-dark ml-1">
                {data.current_level ?? 0}ml
              </span>{" "}
            </button>
          </div>
          <div className="col-md-12 mb-5 mb-md-5">
            <label htmlFor className="font-weight-bolder">
              Add Actual Qty <small>(in ml.)</small>
            </label>
            <input
              className="form-control form-control-lg form-control-solid "
              type="number"
              value={actualQty}
              onChange={(e) => {
                setActualQty(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 my-5">
            <div className="radio-inline">
              <label className="radio radio-danger font-weight-bolder">
                <input
                  type="radio"
                  name="radios12"
                  onClick={() => {
                    setSelected("container");
                  }}
                  checked={selected === "container"}
                />
                <span />
                Add in Pre-made container
              </label>
              <label className="radio radio-danger font-weight-bolder ml-5">
                <input
                  type="radio"
                  name="radios12"
                  onClick={() => {
                    setSelected("waste");
                  }}
                  checked={selected === "waste"}
                />
                <span />
                Waste
              </label>
            </div>
          </div>
          <div className="col-md-12 ">
            <label htmlFor className="font-weight-bolder">
              Add Remarks
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid "
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        <button
          className="btn btn-danger font-weight-bold px-10"
          onClick={onSubmit}
          disabled={actualQty !== "" && selected && !sending ? false : true}
        >
          Ready to Wash{" "}
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
      </div>
    </Modal>
  );
}
