/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import toast from "react-hot-toast";
import { getApiData } from "../../../../../app/Services";
// import { MEDIA_DOMAIN } from "../../../../../app/config";

export function UserProfileDropdown() {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  // console.log('user')
  // console.log(user)
  // console.log(user.photo)

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const onLogout = async () => {
    // setLoading(true);
    return await getApiData(`v1/logout`).then((res) => {
      if (!res.status) {
        toast.error(res.message);
      } else {
        history.push("/logout");
      }
    });
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 text-capitalize">
            {user.name}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            {user.photo ? (
              <img
                alt="Pic"
                src={`${user.photo}`}
                style={{ height: "35px", width: "35px" }}
              />
            ) : (
              <span class="symbol-label font-size-h5 font-weight-bold text-uppercase">
                {user.name[0]}
              </span>
            )}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol mr-3">
                {user.photo ? (
                  <img
                    alt="Pic"
                    src={`${user.photo}`}
                    style={{ height: "50px", width: "50px" }}
                  />
                ) : (
                  <span class="symbol-label font-size-h5 font-weight-bold text-uppercase">
                    {user.name[0]}
                  </span>
                )}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.email}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                  <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                </div>
              </div>
            </div>
          </Link>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a>
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <button
              className="btn btn-light-danger font-weight-bold"
              onClick={() => {
                toast.promise(onLogout(), {
                  loading: "Please wait...",
                  success: "Success!",
                  error: "Failed.",
                });
              }}
            >
              Sign Out
            </button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
