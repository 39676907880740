import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { getAreaByCity, getStateList } from "../../../config";
import { getApiData } from "../../../Services";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function() {
  const history = useHistory();

  const [managerList, setManagerList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    state: "",
    city: "",
    area: "",
    tapri: "",
  });

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
    getManagerList();
  }, []);

  const getManagerList = () => {
    getApiData("v1/users/?type=tapri").then((res) => {
      if (res.status) {
        let tapri = [];
        res.data.map((item) => {
          tapri = [...tapri, { label: item.first_name, value: item.id }];
        });
        setManagerList(tapri);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "state") {
      obj.city = null;
      obj.area = "";
      setAreaList([]);
    } else if (key === "city") {
      obj.area = "";
    }
    setFilters(obj);
  };

  return (
    <>
      <div>
        <Card className="card-spaces">
          <CardBody className="p-4">
            <TableFilter
              stateList={stateList}
              cityList={cityList}
              areaList={areaList}
              value={filters}
              onStateSelect={(selectedState) => {
                if (selectedState.city && selectedState.city.length) {
                  updateFilter(selectedState.value, "state");
                  let city = [];
                  selectedState.city.map((item) => {
                    city = [...city, { label: item, value: item }];
                  });
                  setCityList(city);
                }
              }}
              onCitySelect={async (value) => {
                updateFilter(value, "city");
                const area = await getAreaByCity(value);
                setAreaList(area);
              }}
              onAreaSelect={async (value) => {
                updateFilter(value, "area");
              }}
              setSearchString={(value) => {
                let obj = { ...filters };
                obj.search = value;
                setFilters(obj);
              }}
              onClearFilter={() => {
                setFilters({
                  search: "",
                  status: "",
                  state: "",
                  city: "",
                  area: "",
                });
              }}
            />
          </CardBody>
        </Card>
        <Card>
          <Table
            filters={filters}
            stateList={stateList}
            managerList={managerList}
          />
        </Card>
      </div>
    </>
  );
}
