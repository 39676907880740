import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GeneralDropdown } from "../../../../components";
import { getApiData } from "../../../../Services";

export default function({
  show,
  onHide,
  onSuccess,
  onError,
  list,
  data,
  status,
}) {
  const [qty, setQty] = useState("");
  const [clearFilter, setClearFilter] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState({});
  const [sending, setSending] = useState(false);

  useEffect(() => {
    console.log(data);
    if (data.id) {
      setIngredients(data.ingredients);
    }
  }, [data]);

  const onIngredientAdd = () => {
    console.log(selectedIngredient);
    const obj = {
      id: selectedIngredient.id,
      name: selectedIngredient.name,
      quantity: qty + selectedIngredient.measurement_unit,
    };

    let array = [obj, ...ingredients];
    setQty("");
    setClearFilter(Math.random() * 10);
    setIngredients(array);
  };

  const removeIngredient = (index) => {
    let array = [...ingredients];
    delete array[index];
    let filter = array.filter((e) => e);
    setIngredients(filter);
  };

  const onSubmit = () => {
    if (ingredients.length) {
      const body = {
        ingredients: ingredients,
      };
      setSending(true);
      getApiData(`tapri/v1/batch/${data.id}/`, "put", body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message, res.data);
        } else {
          onError(res.message);
        }
      });
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {status === "running" ? "Add " : "View "} Ingredients{" "}
          <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Batch:</span> {data.batch_id}
          </span>
          <span className="label label-lg label-light label-inline font-weight-bolder ml-2">
            <span className="text-dark mr-1">{data.product_name}</span>
          </span>
        </Modal.Title>
        <button className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <div>
          {status === "running" && (
            <div className="row mt-3">
              <div className="col-md-5 ">
                <GeneralDropdown
                  clearFilter={clearFilter}
                  inModal
                  list={list}
                  handleChange={(obj) => {
                    // setUnit(obj.data);
                    // console.log(obj);
                    setSelectedIngredient(obj.data);
                  }}
                />
              </div>
              <div className="col-md-5  ">
                <div className="input-group input-group-solid ">
                  <input
                    type="number"
                    className="form-control border-right-0"
                    placeholder="Enter Quantity"
                    value={qty}
                    onChange={(e) => {
                      setQty(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && selectedIngredient.id && qty) {
                        onIngredientAdd();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text ">
                      <b className="text-uppercase">
                        {selectedIngredient.measurement_unit ?? "ML"}
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <button
                  className="btn btn-danger font-weight-bold px-10"
                  onClick={onIngredientAdd}
                  disabled={selectedIngredient.id && qty ? false : true}
                >
                  Add
                </button>
              </div>
            </div>
          )}

          <div className="row mt-5">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th>#</th>
                      <th>Ingredient</th>
                      <th>Qty</th>
                      {status === "running" ? (
                        <th>Action</th>
                      ) : (
                        <th>Added DateTime</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {ingredients.length ? (
                      ingredients.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <span className="text-dark-75 font-weight-bolder font-size-md">
                                {index + 1}
                              </span>
                            </td>
                            <td>
                              <span className="text-dark-75 font-weight-bolder font-size-md">
                                {item.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-weight-bolder font-size-sm">
                                {item.quantity}
                              </span>
                            </td>
                            {status === "running" ? (
                              <td className="text-left">
                                <a
                                  onClick={() => removeIngredient(index)}
                                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                >
                                  <i
                                    className="flaticon2-delete text-danger"
                                    style={{ fontSize: "8px" }}
                                  />
                                </a>
                              </td>
                            ) : (
                              <td>
                                <span className="font-weight-bolder font-size-sm">
                                  {data.ingredients_added_at
                                    ? new Date(
                                        data.ingredients_added_at
                                      ).toLocaleString()
                                    : "-"}
                                </span>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="pl-3">No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0 pt-0">
        <button
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        {status === "running" && (
          <button
            className="btn btn-danger font-weight-bold px-10"
            disabled={ingredients.length && !sending ? false : true}
            onClick={onSubmit}
          >
            Submit
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        )}
      </div>
    </Modal>
  );
}
