/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Login from "./Login";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root ">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid "
          id="kt_login"
          style={{ backgroundColor: "#fff" }}
        >
          {/*begin::Aside*/}
          <div
            className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative"
            style={{ backgroundColor: "#f9c6c991" }}
          >
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
              {/*begin::Content*/}
              <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
                {/*begin::Logo*/}
                <a href="#" className="py-9">
                  <img
                    alt="Logo"
                    src="/media/logos/logo-light.png"
                    className="h-125px"
                  />
                </a>
                {/*end::Logo*/}
                {/*begin::Title*/}
                {/* <h1
                  className="fw-bolder fs-2qx pb-5 pb-md-10"
                  style={{ color: "#eb1c26" }}
                >
                  <b>Welcome to Redose</b>
                </h1> */}
                {/*end::Title*/}
                {/*begin::Description*/}
                {/* <p class="fw-bold fs-2" style="color: #986923;">Discover Amazing Metronic */}
                {/* <br />with great build tools</p> */}
                {/*end::Description*/}
              </div>
              {/*end::Content*/}
              {/*begin::Illustration*/}
              <div
                className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
                style={{ backgroundImage: "url(/media/login.png)" }}
              />
              {/*end::Illustration*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          {/* <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden"> */}
          {/*begin::Content header*/}
          {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
          {/*end::Content header*/}

          {/* begin::Content body */}
          {/* <Redirect to="/auth/login" /> */}
          {/* <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0"> */}
          <Switch>
            {/* <ContentRoute path="/" component={Login} exact={true} /> */}
            {/* <ContentRoute path="/" component={Login} exact={true} /> */}
            <ContentRoute path="/sign-in" component={Login} />
            <ContentRoute path="/sign-up" component={Login} />
            {/* <ContentRoute path="/auth/registration" component={Registration} /> */}
            {/* <ContentRoute
              path="/auth/forgot-password"
              component={ForgotPassword}
            /> */}
            {/* <Redirect from="/auth" exact={true} to="/" /> */}
            <Redirect to="/sign-in" />
          </Switch>
          {/* </div> */}
          {/*end::Content body*/}

          {/* begin::Mobile footer */}
          {/* <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2020 Aalacart
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div> */}
          {/* end::Mobile footer */}
          {/* </div> */}
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
