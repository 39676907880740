import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { GeneralDropdown } from "../../../../components";
import { convertBase64, USER_IMAGE } from "../../../../config";
import { getApiData } from "../../../../Services";

export default function({ show, onHide, data, onSuccess, onError, stateList }) {
  const history = useHistory();

  const [sending, setSending] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [tapriList, setTapriList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTapri, setSelectedTapri] = useState(null);
  const [preview, setPreview] = useState(null);
  const [commission, setCommission] = useState("0");

  // const [group, setGroup] = useState("");

  useEffect(() => {
    console.log(data);
    if (data.id) {
      setPreview(data.profile_picture);
      setEmail(data.email);
      setPhone(data.phone_number);
      setUsername(data.username);
      setName(data.first_name);
      setPassword("Not_Allowed");
      setCommission(data.commission);
    } else {
      setPreview(null);
      setEmail("");
      setPhone("");
      setUsername("");
      setName("");
      setPassword("");
      setCommission("");
    }
    // setPreview(USER_IMAGE);
  }, [data]);

  useEffect(() => {
    if (stateList.length && data.state) {
      stateList.map((item) => {
        if (item.value === data.state) {
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList, data]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (cityList.length && data.city) {
      cityList.map((item) => {
        if (item.value === data.city) {
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList, data]);

  useEffect(() => {
    if (areaList.length && data.area) {
      areaList.map((item) => {
        if (item.value === data.area) {
          setSelectedArea(item);
          return;
        }
      });
    }
  }, [areaList, data]);

  useEffect(() => {
    if (tapriList.length && data.tapri) {
      tapriList.map((item) => {
        if (item.value === data.tapri) {
          setSelectedTapri(item);
          return;
        }
      });
    }
  }, [tapriList, data]);

  useEffect(() => {
    if (selectedArea && selectedArea.value) {
      getTapriByArea(selectedArea.value);
    }
  }, [selectedArea]);

  useEffect(() => {
    if (selectedCity && selectedCity.value) {
      getAreaByCity(selectedCity.value);
    }
  }, [selectedCity]);

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    let image = `${base64}`;
    setPreview(image);
  };

  const getAreaByCity = (city) => {
    getApiData(`v1/areas/?city=${city}`).then((res) => {
      if (res.status) {
        let area = [];
        res.data.map((item) => {
          area = [...area, { label: item.name, value: item.id }];
        });
        setAreaList(area);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const getTapriByArea = (area) => {
    getApiData(`v1/tapris/?area=${area}`).then((res) => {
      if (res.status) {
        let tapri = [];
        res.data.map((item) => {
          tapri = [...tapri, { label: item.name, value: item.id }];
        });
        setTapriList(tapri);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const onSubmit = () => {
    if (
      name &&
      username &&
      email &&
      password &&
      selectedState &&
      selectedCity &&
      selectedArea &&
      selectedTapri &&
      commission
    ) {
      setSending(true);
      const body = {
        first_name: name,
        area: selectedArea.value,
        username: username,
        phone_number: phone,
        email: email,
        tapri: selectedTapri.value,
        commission: commission,
      };

      if (!data.id) {
        body.password = password;
      }

      if (preview && !preview.includes("http")) {
        body.profile_picture = preview;
      }

      const api = data.id ? `v1/chhotus/${data.id}/` : `v1/chhotus/`;
      const method = data.id ? "put" : "post";

      getApiData(api, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message, res.data);
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError(Object.values(res.message)[0]);
          }
        }
      });
    }
  };
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {data.id ? "Edit" : "Add"} Chhotu
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {/* <div className="row">
          <div className="d-flex mx-1"> */}
        {/* <Avatar
              width={150}
              height={145}
              onCrop={(preview) => setPreview(preview)}
              onClose={() => {
                setPreview(USER_IMAGE);
              }}
              src={USER_IMAGE}
            /> */}
        {/* {preview && (
              <img
                src={preview}
                alt="Preview"
                style={{
                  height: "145px",
                  width: "150px",
                  marginLeft: "auto",
                }}
              />
            )} */}
        {/* </div>
        </div> */}
        <div className="row" style={{ width: "113%" }}>
          <div className="row col-md-4">
            <div className="col-md-12">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  disabled={data.id}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  disabled={data.id}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row col-md-4">
            <div className="col-md-12">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 row">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Image</label>
              <input
                className="form-control"
                type="file"
                onChange={handleFileRead}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={preview ? preview : USER_IMAGE}
                style={{
                  marginTop: "10px",
                  height: "65px",
                  objectFit: "contain",
                }}
                className="mx-auto"
              />
              {preview && (
                <a
                  href="javascript:;"
                  onClick={() => {
                    setPreview(null);
                  }}
                >
                  Remove Avatar
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group mt-5">
              <label>Select State</label>
              <GeneralDropdown
                selectedOption={selectedState}
                list={stateList}
                inModal
                handleChange={(obj) => {
                  setSelectedState(obj);
                  setSelectedCity(null);
                  setSelectedArea(null);
                  setSelectedTapri(null);
                  if (obj && obj.city && obj.city.length) {
                    let city = [];
                    obj.city.map((item) => {
                      city = [...city, { label: item, value: item }];
                    });
                    setCityList(city);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mt-5">
              <label>Select City</label>
              <GeneralDropdown
                selectedOption={selectedCity}
                list={cityList}
                inModal
                handleChange={(obj) => {
                  setSelectedArea(null);
                  setSelectedTapri(null);
                  setSelectedCity(obj);
                  setTapriList([]);
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mt-5">
              <label>Select Area</label>
              <GeneralDropdown
                selectedOption={selectedArea && selectedArea.value}
                // selectedOption={selectedArea}
                list={areaList}
                inModal
                handleChange={(obj) => {
                  setTapriList([]);
                  setSelectedTapri(null);
                  setSelectedArea(obj);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Select Tapri</label>
              <GeneralDropdown
                selectedOption={selectedTapri && selectedTapri.value}
                list={tapriList}
                inModal
                handleChange={(obj) => {
                  setSelectedTapri(obj);
                }}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group is-invalid mb-0">
              <label>Phone</label>
              <input
                type="number"
                className="form-control"
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setPhone(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Commission</label>
              <input
                type="text"
                className="form-control"
                value={commission}
                onChange={(e) => {
                  setCommission(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary px-5"
          onClick={onSubmit}
          disabled={
            name &&
            username &&
            email &&
            password &&
            selectedState &&
            selectedCity &&
            selectedArea &&
            selectedTapri &&
            commission &&
            !sending
              ? false
              : true
          }
        >
          Save
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
