import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import { GeneralDropdown } from "../../../../components";

export default function({ show, onHide, onSuccess, onError, data, stateList }) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (data.id) {
      setName(data.name);
    } else {
      setName("");
    }
  }, [data]);

  useEffect(() => {
    if (stateList.length && data.state) {
      stateList.map((item) => {
        if (item.value === data.state) {
          // console.log(item);
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList, data]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (cityList.length && data.city) {
      cityList.map((item) => {
        if (item.value === data.city) {
          console.log("City ");
          console.log(item);
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList, data]);

  const onSubmit = () => {
    setSending(true);
    const body = {
      name: name,
      city: selectedCity.value,
      state: selectedState.value,
    };

    let method = data.id ? "put" : "post";
    let param = data.id ? data.id + "/" : "";

    getApiData(`v1/areas/${param}`, method, body).then((res) => {
      setSending(false);
      if (res.status) {
        onSuccess(res.message, res.data);
      } else {
        onError(res.message);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Area</Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Area Name</label>
              <input
                className="form-control"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Select State</label>
              <GeneralDropdown
                selectedOption={data.state ? data.state : null}
                list={stateList}
                inModal
                handleChange={(obj) => {
                  setSelectedState(obj);
                  setSelectedCity(null);
                  if (obj && obj.city && obj.city.length) {
                    let city = [];
                    obj.city.map((item) => {
                      city = [...city, { label: item, value: item }];
                    });
                    setCityList(city);
                  }
                  //   props.onStateSelect(obj);
                }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Select City</label>
              <GeneralDropdown
                selectedOption={data.city ? data.city : null}
                list={cityList}
                inModal
                handleChange={(obj) => {
                  setSelectedCity(obj);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary px-5"
          onClick={onSubmit}
          disabled={
            name && selectedCity && selectedState && !sending ? false : true
          }
        >
          Submit
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
