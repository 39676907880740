import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  CardBody,
  CardHeader,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import { DeleteModal, Loader } from "../../../../components";
import {
  getParams,
  PER_PAGE,
  sizePerPageList,
  USER_IMAGE,
} from "../../../../config";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../../Services";
import { UserModal } from "../modals";

function UserTable(props) {
  const history = useHistory();
  const { user_type } = useSelector((state) => state.auth.user);

  const [userModal, setUserModal] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`v1/chhotus/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        // alert(authToken);
        history.push("/logout");
      }
    });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "first_name",
      text: "Name",
      headerStyle: () => {
        return { minWidth: "150px" };
      },
      formatter: (_, row) => (
        <>
          <div class="d-flex align-items-center">
            <img
              class=""
              src={row.profile_picture ? row.profile_picture : USER_IMAGE}
              className="rounded"
              style={{ height: "35px", width: "35px", objectFit: "cover" }}
              alt="photo"
            />

            <div class="ml-4">
              <div class="text-dark-75 font-weight-bolder font-size-lg mb-0 mr-3">
                {row.first_name}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      dataField: "username",
      text: "Username",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "phone_number",
      text: "Phone",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
      formatter: (_, row) => (
        <a href={`tel:${row.phone_number}`}>{row.phone_number}</a>
      ),
    },
    {
      dataField: "state",
      text: "State",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "city",
      text: "City",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (_, row) => (
        <>
          <button
            className="btn btn-icon btn-light btn-hover-secondary btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setUserModal(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-secondary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </button>
          <> </>

          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setDeleteModal(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </>
      ),
      // classes: 'text-right pr-0',
      // headerClasses: 'text-right pr-3',
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };
  return (
    <>
      <UserModal
        show={userModal}
        stateList={props.stateList}
        data={selectedRow}
        onHide={() => setUserModal(!userModal)}
        onSuccess={(message) => {
          setUserModal(false);
          getTableRecords();
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {user_type === "admin" && (
        <CardHeader className="px-0 mt-4 custom-card-header">
          <div className="row col-sm-12 pr-0 ">
            <div>
              <button
                onClick={() => {
                  setSelectedRow({});
                  setUserModal(true);
                }}
                className="btn btn-primary float-right"
              >
                Add New Chhotu
              </button>
            </div>
          </div>
        </CardHeader>
      )}
      <CardBody>
        {selectedRow && (
          <DeleteModal
            show={deleteModal}
            api="v1/chhotus"
            title="Delete Chhotu"
            message="Are you sure to permanently delete this chhotu?"
            onHide={() => setDeleteModal(!deleteModal)}
            id={selectedRow.id}
            onSuccess={(message) => {
              getTableRecords();
              toast.success(message);
            }}
            onFailed={(message) => {
              toast.error(message);
            }}
          />
        )}

        {loading ? (
          <Loader />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    onTableChange={() => {}}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    id="userTable"
                    bootstrap4
                    keyField="id"
                    data={data === null ? [] : data}
                    columns={columns}
                    // cellEdit={cellEditFactory({
                    //   mode: "click",
                    //   blurToSave: true,
                    // })}
                    noDataIndication={() => {
                      return "No Data to Display";
                    }}
                    defaultSorted={[{ dataField: "id", order: "asc" }]}
                    {...paginationTableProps}
                  >
                    {/* <PleaseWaitMessage entities={data} /> */}
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )}
      </CardBody>
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
