import React from "react";
import "./../../../components/loader.css";
export default function Loader() {
  return (
    <>
      <div className="row cus-row col-lg-12">
        <div className="mt-5 line-loader-3"></div>
      </div>
      <div className="row cus-row col-lg-12">
        <div className="mt-5 line-loader-3"></div>
      </div>
      <div className="row cus-row col-lg-12">
        <div className="mt-5 line-loader-3"></div>
      </div>
      <div className="row cus-row col-lg-12">
        <div className="mt-5 line-loader-3"></div>
      </div>
      <div className="row cus-row col-lg-12">
        <div className="mt-5 line-loader-3"></div>
      </div>
      <div className="row cus-row col-lg-12">
        <div className="my-5 line-loader-3"></div>
      </div>
    </>
  );
}
