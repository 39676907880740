import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { GeneralDropdown } from "../../../../components";
import { DateRangeSettings } from "../../../../config";
export default function(props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [clearFilter, setClearFilter] = useState(null);

  const handleApply = (event, picker) => {
    props.setDate(
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD")
    );
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-custom gutter-b card-spaces">
            <div className="card-body p-4">
              <div className="row cus-filter-column">
                <div className="col-lg-2 col-md-6 col-sm-6 mb-5 mb-lg-0">
                  <GeneralDropdown
                    clearFilter={clearFilter}
                    list={props.productList}
                    handleChange={(obj) => {
                      props.setTypeValue(obj.value);
                    }}
                  />
                  <small className="form-text text-muted">
                    Filter by <b>Type</b>
                  </small>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-5 mb-lg-0">
                  <GeneralDropdown
                    clearFilter={clearFilter}
                    list={props.utensilList}
                    handleChange={(obj) => {
                      props.setUtensilValue(obj.value);
                    }}
                  />
                  <small className="form-text text-muted">
                    Filter by <b>Utensil</b>
                  </small>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-5 mb-lg-0">
                  <div className="input-group">
                    <DateRangePicker
                      initialSettings={DateRangeSettings}
                      onApply={handleApply}
                      onCancel={handleCancel}
                      max
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select date range"
                        id="date-range"
                        autoComplete="off"
                      />
                    </DateRangePicker>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="far fa-calendar-check" />
                      </span>
                    </div>
                  </div>
                  <small className="form-text text-muted">
                    Filter by <b>Date</b>
                  </small>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-5 mb-lg-0">
                  <div className="input-group" id>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (e.target.value.length > 2) {
                          setIsSearching(true);
                          props.setSearchString(e.target.value);
                        } else {
                          if (isSearching) {
                            props.setSearchString("");
                            setIsSearching(false);
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsSearching(true);
                          props.setSearchString(search);
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-white">
                        <i className="la la-search" />
                      </span>
                    </div>
                  </div>
                  <small className="form-text text-muted">
                    <b>Search</b> in all fields
                  </small>
                </div>
                <div className="col-lg-1 ">
                  <button
                    className="btn btn-block btn-light-danger px-6 font-weight-bolder"
                    onClick={() => {
                      props.onClearFilter();
                      setSearch("");
                      setClearFilter(Math.random());
                      window.$("#date-range").val("");
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
