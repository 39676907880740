import React from "react";

export default function () {
  return (
    <div>
      {/*begin::Mixed Widget 14*/}
      <div className="card card-custom   gutter-b card-stretch" style={{}}>
        <div className="card-header">
          <h3 className="card-title">All Product</h3>
        </div>
        {/*begin::Body*/}
        <div className="card-body px-3 table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Sales</th>
                <th>Units</th>
                <th>COGS</th>
                <th>Profit</th>
                <th>Waste Units</th>
                <th>Waste Revenue</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/6940/6940354.png"
                    className="h-75 align-self-end rounded"
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td>
                  <a href className="font-weight-bolder text-danger">
                    Ragular Tea
                  </a>
                </td>
                <td>₹8,450</td>
                <td>845</td>
                <td>₹10</td>
                <td>₹3,380</td>
                <td>20</td>
                <td>₹380</td>
                <td>
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Up-right.svg*/}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(11.646447, 12.853553) rotate(-315.000000) translate(-11.646447, -12.853553) "
                          x="10.6464466"
                          y="5.85355339"
                          width={2}
                          height={14}
                          rx={1}
                        />
                        <path
                          d="M8.1109127,8.90380592 C7.55862795,8.90380592 7.1109127,8.45609067 7.1109127,7.90380592 C7.1109127,7.35152117 7.55862795,6.90380592 8.1109127,6.90380592 L16.5961941,6.90380592 C17.1315855,6.90380592 17.5719943,7.32548256 17.5952502,7.8603687 L17.9488036,15.9920967 C17.9727933,16.5438602 17.5449482,17.0106003 16.9931847,17.0345901 C16.4414212,17.0585798 15.974681,16.6307346 15.9506913,16.0789711 L15.6387276,8.90380592 L8.1109127,8.90380592 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/3862/3862488.png"
                    className="h-75 align-self-end rounded"
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td>
                  <a href className="font-weight-bolder text-danger">
                    Masala Tea
                  </a>
                </td>
                <td>₹8,450</td>
                <td>845</td>
                <td>₹10</td>
                <td>₹3,380</td>
                <td>20</td>
                <td>₹380</td>
                <td>
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Up-right.svg*/}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(11.646447, 12.853553) rotate(-315.000000) translate(-11.646447, -12.853553) "
                          x="10.6464466"
                          y="5.85355339"
                          width={2}
                          height={14}
                          rx={1}
                        />
                        <path
                          d="M8.1109127,8.90380592 C7.55862795,8.90380592 7.1109127,8.45609067 7.1109127,7.90380592 C7.1109127,7.35152117 7.55862795,6.90380592 8.1109127,6.90380592 L16.5961941,6.90380592 C17.1315855,6.90380592 17.5719943,7.32548256 17.5952502,7.8603687 L17.9488036,15.9920967 C17.9727933,16.5438602 17.5449482,17.0106003 16.9931847,17.0345901 C16.4414212,17.0585798 15.974681,16.6307346 15.9506913,16.0789711 L15.6387276,8.90380592 L8.1109127,8.90380592 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/924/924514.png"
                    className="h-75 align-self-end rounded"
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td>
                  <a href className="font-weight-bolder text-danger">
                    Hot Coffee
                  </a>
                </td>
                <td>₹8,450</td>
                <td>845</td>
                <td>₹10</td>
                <td>₹3,380</td>
                <td>20</td>
                <td>₹380</td>
                <td>
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Up-right.svg*/}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(11.646447, 12.853553) rotate(-315.000000) translate(-11.646447, -12.853553) "
                          x="10.6464466"
                          y="5.85355339"
                          width={2}
                          height={14}
                          rx={1}
                        />
                        <path
                          d="M8.1109127,8.90380592 C7.55862795,8.90380592 7.1109127,8.45609067 7.1109127,7.90380592 C7.1109127,7.35152117 7.55862795,6.90380592 8.1109127,6.90380592 L16.5961941,6.90380592 C17.1315855,6.90380592 17.5719943,7.32548256 17.5952502,7.8603687 L17.9488036,15.9920967 C17.9727933,16.5438602 17.5449482,17.0106003 16.9931847,17.0345901 C16.4414212,17.0585798 15.974681,16.6307346 15.9506913,16.0789711 L15.6387276,8.90380592 L8.1109127,8.90380592 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/3754/3754648.png"
                    className="h-75 align-self-end rounded"
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td>
                  <a href className="font-weight-bolder text-danger">
                    Cold Coffee
                  </a>
                </td>
                <td>₹8,450</td>
                <td>845</td>
                <td>₹10</td>
                <td>₹3,380</td>
                <td>20</td>
                <td>₹380</td>
                <td>
                  <span className="svg-icon svg-icon-danger svg-icon-2x">
                    {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Down-right.svg*/}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(11.646447, 12.146447) rotate(-225.000000) translate(-11.646447, -12.146447) "
                          x="10.6464466"
                          y="5.14644661"
                          width={2}
                          height={14}
                          rx={1}
                        />
                        <path
                          d="M15.5961941,8.6109127 C15.5961941,8.05862795 16.0439093,7.6109127 16.5961941,7.6109127 C17.1484788,7.6109127 17.5961941,8.05862795 17.5961941,8.6109127 L17.5961941,17.0961941 C17.5961941,17.6315855 17.1745174,18.0719943 16.6396313,18.0952502 L8.50790332,18.4488036 C7.95613984,18.4727933 7.48939965,18.0449482 7.46540994,17.4931847 C7.44142022,16.9414212 7.86926539,16.474681 8.42102887,16.4506913 L15.5961941,16.1387276 L15.5961941,8.6109127 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Mixed Widget 14*/}
    </div>
  );
}
