import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CardBody, Pagination } from "../../../../_metronic/_partials/controls";
import { DeleteModal, Loader } from "../../../components";
import {
  convertDate,
  getParams,
  PER_PAGE,
  sizePerPageList,
  timeSince,
} from "../../../config";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../Services";
import { CreditModal } from "./../modals";

function UserTable(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`v1/customers/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        // alert(authToken);
        history.push("/logout");
      }
    });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "first_name",
      text: "Name",
      headerStyle: () => {
        return { minWidth: "150px" };
      },
      formatter: (_, row) => (
        <>
          <div class="d-flex align-items-center">
            <span class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold text-uppercase">
                {row.first_name && row.first_name[0]}
              </span>
            </span>
            <div class="ml-4">
              <div class="text-dark-75 font-weight-bolder font-size-lg mb-0 mr-3">
                {row.first_name}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      dataField: "phone",
      text: "Phone",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
      formatter: (_, row) => <a href={`tel:${row.phone}`}>{row.phone}</a>,
    },
    {
      dataField: "available_credit",
      text: "Available Credit",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "negative_upto",
      text: "Negative upto",
      formatter: (_, row) => (
        <div>
          <span>{row.negative_upto}</span>
          <span
            className="svg-icon svg-icon-sm ml-2 cursor-pointer"
            onClick={() => {
              setCreditModal(true);
              setSelectedRow(row);
            }}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </div>
      ),
      headerStyle: () => {
        return { minWidth: "120px" };
      },
    },
    {
      dataField: "created_at",
      text: "Created At",
      headerStyle: () => {
        return { minWidth: "130px" };
      },
      formatter: (_, row) => (
        <div>
          <span>{convertDate(row.date_joined)}</span>
          <br />
          <span className="timeStampColor">({timeSince(row.date_joined)})</span>
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (_, row) => (
        <>
          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setDeleteModal(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </>
      ),
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };

  return (
    <>
      <CreditModal
        show={creditModal}
        data={selectedRow}
        onHide={() => setCreditModal(false)}
        onSuccess={(message) => {
          setCreditModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <CardBody>
        <DeleteModal
          show={deleteModal}
          api="v1/customers"
          title="Delete Customer"
          message="Are you sure to permanently delete this customer?"
          onHide={() => setDeleteModal(!deleteModal)}
          id={selectedRow.id}
          onSuccess={(message) => {
            getTableRecords();
            toast.success(message);
          }}
          onFailed={(message) => {
            toast.error(message);
          }}
        />

        {loading ? (
          <Loader />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    onTableChange={() => {}}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    id="userTable"
                    bootstrap4
                    keyField="id"
                    data={data === null ? [] : data}
                    columns={columns}
                    noDataIndication={() => {
                      return "No Data to Display";
                    }}
                    defaultSorted={[{ dataField: "id", order: "asc" }]}
                    {...paginationTableProps}
                  ></BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )}
      </CardBody>
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
