import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { fetchToken } from "./config/firebase";
import {
  AreaMaster,
  Batch,
  Buildings,
  ChhotuMaster,
  Collect,
  CollectUtensil,
  Customers,
  DashboardCustomer,
  DashboardSales,
  Deliver,
  DeliverUtensil,
  IngredientsMaster,
  LiveOrders,
  ManageBuilding,
  ProductsMaster,
  ReadyToWash,
  RequestBuilding,
  RiderMaster,
  SpotMaster,
  TapriMaster,
  Thermos,
  UserDashboard,
  Users,
  UtensilsMaster,
  Washing,
  Wastage,
  LiveLocation,
} from "./pages";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  React.useEffect(() => {
    if (user.user_type !== "admin") {
      let unauthorizedRoutes = [];
      // console.log(history.location.pathname)
      if (unauthorizedRoutes.includes(history.location.pathname)) {
        history.push("unauthorized");
      }
    }
  }, [user]);

  React.useEffect(() => {
    fetchToken();
  }, []);
  let isAdmin = user && user.user_type === "admin" ? true : false;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {// <>
        isAdmin ? (
          <Redirect
            exact
            from={["/", "/dashboard"]}
            to="/dashboard/sales-analytics"
          />
        ) : (
          <Redirect exact from={"/"} to="/dashboard" />
        )
        // {/* <Redirect exact from="/dashboard" to="/dashboard/overview" /> */}
        // </>
        }
        <ContentRoute path="/user-profile" component={UserProfilepage} />

        {isAdmin ? (
          <>
            <ContentRoute
              path="/dashboard/customer-analytics"
              component={DashboardCustomer}
            />
            <ContentRoute
              path="/dashboard/sales-analytics"
              component={DashboardSales}
            />
            <ContentRoute path="/users" component={Users} />
            <ContentRoute path="/live-location" component={LiveLocation} />
            <ContentRoute path="/customers" component={Customers} />
            <ContentRoute path="/master/area" component={AreaMaster} />
            <ContentRoute path="/master/tapri" component={TapriMaster} />
            <ContentRoute path="/master/spot" component={SpotMaster} />
            <ContentRoute path="/master/rider" component={RiderMaster} />
            <ContentRoute path="/master/utensils" component={UtensilsMaster} />
            <ContentRoute path="/master/products" component={ProductsMaster} />
            <ContentRoute path="/master/chhotu" component={ChhotuMaster} />
            <ContentRoute
              path="/master/ingredients"
              component={IngredientsMaster}
            />
            <ContentRoute path="/master/building" component={Buildings} exact />
            <ContentRoute
              path="/master/building/edit"
              component={ManageBuilding}
            />
            <ContentRoute
              path="/master/building/create"
              component={ManageBuilding}
            />
            <ContentRoute
              path="/master/building-request"
              component={RequestBuilding}
            />
          </>
        ) : (
          <>
            <ContentRoute path="/dashboard" component={UserDashboard} />
            {/* <ContentRoute path="/chhotu" component={Chhotu} /> */}
            <ContentRoute path="/batch" component={Batch} />
            <ContentRoute path="/thermos" component={Thermos} />
            <ContentRoute path="/collect" component={Collect} exact />
            <ContentRoute path="/collect/:id" component={CollectUtensil} />
            <ContentRoute path="/deliver" component={Deliver} exact />
            <ContentRoute path="/deliver/:id" component={DeliverUtensil} />
            <ContentRoute path="/ready-to-wash" component={ReadyToWash} />
            <ContentRoute path="/live-orders" component={LiveOrders} />
            <ContentRoute path="/washing" component={Washing} />
            <ContentRoute path="/wastage" component={Wastage} />
          </>
        )}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
