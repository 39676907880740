import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { convertBase64 } from "../../../../config";
import { getApiData } from "../../../../Services";

export default function({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [variations, setVariations] = useState({
    regular: {
      price: "",
      capacity: "",
    },
  });
  const [imageRegular, setImageRegular] = useState("");
  const [imageBlack, setImageBlack] = useState("");
  const [imageWhite, setImageWhite] = useState("");

  useEffect(() => {
    if (data.id) {
      console.log(data);
      setName(data.name);
      setVariations(data.variations);
      setImageBlack(data.image.black);
      setImageWhite(data.image.white);
      setImageRegular(data.image.regular);
    } else {
      setName("");
      setVariations({
        regular: {
          price: "",
          capacity: "",
        },
      });
      setImageBlack("");
      setImageWhite("");
      setImageRegular("");
    }
  }, [data]);

  const updateValue = (value, key, item) => {
    let variations_ = { ...variations };
    if (variations_[item]) {
      let obj = {
        [key]: value,
      };
      variations_[item] = { ...variations_[item], ...obj };
      console.log(variations_);
      setVariations(variations_);
    }
  };

  const onSubmit = () => {
    if (name && imageRegular && imageBlack && imageWhite) {
      setSending(true);

      const body = {
        name: name,
        variations: variations,
        image: {},
      };
      // regular: imageRegular,
      // black: imageBlack,
      // white: imageWhite,

      if (!imageRegular.includes("https://") && imageRegular) {
        body.image.regular = imageRegular;
      }
      if (!imageBlack.includes("https://") && imageBlack) {
        body.image.black = imageBlack;
      }
      if (!imageWhite.includes("https://") && imageWhite) {
        body.image.white = imageWhite;
      }

      let method = data.id ? "put" : "post";
      let param = data.id ? data.id + "/" : "";

      getApiData(`v1/products/${param}`, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message, res.data);
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError("Something went wrong!");
          }
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Manage Product</Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 form-group">
            <label>Name</label>
            <input
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 form-group">
            <div className="d-flex">
              <label>Variations</label>
              <a
                className="ml-auto"
                href="javascript:;"
                onClick={() => {
                  let variations_ = { ...variations };
                  let blankObj = {
                    price: "",
                    capacity: "",
                  };
                  variations_[""] = blankObj;
                  console.log(variations_);
                  setVariations(variations_);
                }}
              >
                Add More
              </a>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Capacity</th>
                  <th>Price</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(variations).map((item) => {
                  return (
                    <tr className="mb-2">
                      <td>
                        <input
                          className="form-control mr-2"
                          placeholder="Name"
                          value={item}
                          onChange={(e) => {
                            let newObj = {};

                            Object.keys(variations).map((item1) => {
                              if (item1 === item) {
                                newObj[e.target.value] = variations[item];
                              } else {
                                newObj[item1] = variations[item1];
                              }
                            });
                            setVariations(newObj);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control mr-2"
                          placeholder="Capacity"
                          value={variations[item].capacity}
                          onChange={(e) => {
                            updateValue(e.target.value, "capacity", item);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control mr-2"
                          placeholder="Price"
                          value={variations[item].price}
                          onChange={(e) => {
                            updateValue(e.target.value, "price", item);
                          }}
                        />
                      </td>
                      <td className="d-flex justify-content-center">
                        {Object.keys(variations).length > 1 && (
                          <button
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => {
                              let variations_ = { ...variations };
                              delete variations_[item];
                              // variations.map((item1) => {
                              //   if (item1.sku !== item.sku) {
                              //     variations_ = [...variations_, item1];
                              //   }
                              // });
                              console.log(variations_);
                              setVariations(variations_);
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              {/*begin::Svg Icon | path:/media/svg/icons/General/Trash.svg*/}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x={0} y={0} width={24} height={24} />
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                  />
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  />
                                </g>
                              </svg>
                              {/*end::Svg Icon*/}
                            </span>
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="col-md-12 row">
            <div className="col-md-4 ">
              <div className="form-group px-0 mb-3">
                <label>Image Regular</label>
                <input
                  className="form-control"
                  type="file"
                  onChange={async (event) => {
                    const file = event.target.files[0];
                    const base64 = await convertBase64(file);
                    let image = `${base64}`;
                    setImageRegular(image);
                  }}
                />
              </div>
              {imageRegular && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={imageRegular}
                    style={{
                      height: "60px",
                      objectFit: "contain",
                    }}
                    className="mx-auto mr-1"
                  />
                  <a
                    href="javascript:;"
                    onClick={() => {
                      setImageRegular(null);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
            <div className="col-md-4 ">
              <div className="form-group px-0 mb-3">
                <label>Image Black</label>
                <input
                  className="form-control"
                  type="file"
                  onChange={async (event) => {
                    const file = event.target.files[0];
                    const base64 = await convertBase64(file);
                    let image = `${base64}`;
                    setImageBlack(image);
                  }}
                />
              </div>
              {imageBlack && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={imageBlack}
                    style={{
                      height: "60px",
                      objectFit: "contain",
                    }}
                    className="mx-auto mr-1"
                  />
                  <a
                    href="javascript:;"
                    onClick={() => {
                      setImageBlack(null);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
            <div className="col-md-4 ">
              <div className="form-group px-0 mb-3">
                <label>Image White</label>
                <input
                  className="form-control"
                  type="file"
                  onChange={async (event) => {
                    const file = event.target.files[0];
                    const base64 = await convertBase64(file);
                    let image = `${base64}`;
                    setImageWhite(image);
                  }}
                />
              </div>
              {imageWhite && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={imageWhite}
                    style={{
                      height: "60px",
                      objectFit: "contain",
                    }}
                    className="mx-auto mr-1"
                  />
                  <a
                    href="javascript:;"
                    onClick={() => {
                      setImageWhite(null);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary px-5"
          onClick={onSubmit}
          disabled={
            name && imageRegular && imageBlack && imageWhite && !sending
              ? false
              : true
          }
        >
          Submit
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
