import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { SelectStyle } from "../../config";

export default function(props) {
  const selectInputRef = useRef();

  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    // if (props.list && props.list.length) {
    // let mainArray = [];
    // props.list.map((item, i) => {
    //   mainArray = [
    //     ...mainArray,
    //     {
    //       label: item.name,
    //       value: item.id,
    //       data: item.data ? item.data : [],
    //     },
    //   ];
    // });
    setOptions(props.list);
    // }
  }, [props.list]);

  useEffect(() => {
    // console.log("in clear");
    if (props.clearFilter) {
      // console.log(props.clearFilter);
      selectInputRef.current.select.clearValue();
      setSelectedValue(null);
    }
  }, [props.clearFilter]);

  // useEffect(() => {
  //   // console.log("props.selectedArray");
  //   // console.log(props.selectedArray);
  //   setSelectedValue(props.selectedArray);
  // }, [props.selectedArray]);

  useEffect(() => {
    // console.log('props.selectedOption')
    // console.log(props.selectedOption)
    // console.log(options)
    if (props.selectedOption) {
      options.filter((item) => {
        // console.log(item.value == props.selectedOption)
        if (item.value == props.selectedOption) {
          // console.log(item)
          setSelectedValue(item);
          return;
        }
      });
    }
  }, [options]);

  useEffect(() => {
    if (props.selectedOption) {
      // console.log("props.selectedOption");
      // console.log(props.selectedOption.value);
      options.filter((item) => {
        // console.log(item)
        if (item.value === props.selectedOption.value) {
          // console.log(item);
          // console.log("Selected general dropdown");
          setSelectedValue(item);
          return;
        }
      });
    } else {
      selectInputRef.current.select.clearValue();
      setSelectedValue(null);
    }
  }, [props.selectedOption]);

  return (
    <Select
      ref={selectInputRef}
      isMulti={props.isMulti}
      {...(!props.inModal && { menuPortalTarget: document.body })}
      menuPosition={"fixed"}
      options={options}
      placeholder={props.placeholder}
      {...(selectedValue && { value: selectedValue })}
      isDisabled={props.disabled}
      //   value={props.selectedOption}
      onChange={(data) => {
        if (data) {
          setSelectedValue(data);
          props.handleChange(data);
        }
      }}
      styles={SelectStyle}
    />
  );
}
