import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { getApiData } from "../../../Services";
import QrScannerModal from "./Camera";

export default function({ show, onHide, onSuccess, onError, api }) {
  const inputRef = useRef();

  // const [value, setValue] = useState("");
  // const [sending, setSending] = useState(false);

  const onSubmit = (value) => {
    // setSending(true);
    const body = {
      code: value,
    };

    if (api.includes("utensil/status/change")) {
      body.type = "washing";
    }

    getApiData(api, "post", body).then((res) => {
      // setSending(false);
      toast.dismiss();
      if (res.status) {
        onHide();
        inputRef.current.value = "";
        onSuccess(res.message);
      } else {
        if (typeof res.message === "string") {
          onError(res.message);
        } else {
          onError(Object.values(res.message)[0]);
        }
      }
    });
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Scan QR code{" "}
          {/* <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Batch:</span> 1997
          </span> */}
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="row">
          <div className="col-md-12 mb-8" />
          <div className="col-md-12 text-center mb-5 mb-md-5">
            <QrScannerModal
              // value={value}
              // setValue={(value) => {
              //   setValue(value);
              // }}
              onScan={(code) => {
                if (inputRef.current.value !== code) {
                  inputRef.current.value = code;
                  onSubmit(code);
                }
              }}
            />
          </div>
          <div className="col-md-12  text-center">
            <input
              ref={inputRef}
              className="form-control"
              type="text"
              placeholder="Enter thermos code manually"
              onKeyDown={(e) => {
                if (e.key === "Enter" && inputRef.current.value) {
                  if (inputRef.current.value) {
                    toast.loading("Please wait...");
                    onSubmit(inputRef.current.value);
                  } else {
                    toast.error("Please enter thermos code.");
                  }
                }
              }}
              // value={value}
              // onBlur={(e) => {
              //   setValue(e.target.value);
              // }}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-danger font-weight-bold px-10"
          // disabled={!sending ? false : true}
          onClick={() => {
            console.log(inputRef.current.value);
            if (inputRef.current.value) {
              toast.loading("Please wait...");
              onSubmit(inputRef.current.value);
            } else {
              toast.error("Please enter thermos code.");
            }
          }}
        >
          Submit
          {/* {sending && <Spinner animation="border" size="sm" className="ml-1" />} */}
        </button>
      </div>
    </Modal>
  );
}
