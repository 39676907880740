import React from "react";

export default function Filter() {
  return (
    <div className="card card-custom gutter-b card-spaces mb-3">
      <div className="card-body p-4">
        <div className="row cus-filter-column">
          <div className="col-lg-2 mb-5 mb-lg-0 ">
            <div className="input-group" id="kt_daterangepicker_2">
              <input
                type="text"
                className="form-control"
                readOnly="readonly"
                placeholder="Select date range"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="la la-calendar-check-o" />
                </span>
              </div>
            </div>
            <small className="form-text text-muted">
              Filter by <b>Daterange</b>
            </small>
          </div>
          <div className="col-lg-9  ">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-sm-6 mb-5 mb-lg-0">
                <select name className="form-control" id>
                  <option value disabled selected>
                    Team member
                  </option>
                  <option value />
                </select>
                <small className="form-text text-muted">
                  Filter by <b>team member</b>
                </small>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 mb-5 mb-lg-0">
                <select name className="form-control" id>
                  <option value disabled selected>
                    Select Tapri
                  </option>
                  <option value />
                </select>
                <small className="form-text text-muted">
                  Filter by <b>Tapri</b>
                </small>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 mb-5 mb-lg-0">
                <select name className="form-control" id>
                  <option value disabled selected>
                    Select City
                  </option>
                  <option value />
                </select>
                <small className="form-text text-muted">
                  Filter by <b>City</b>
                </small>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 mb-5 mb-lg-0">
                <select name className="form-control" id>
                  <option value disabled selected>
                    Select Area
                  </option>
                  <option value />
                </select>
                <small className="form-text text-muted">
                  Filter by <b>Area</b>
                </small>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 mb-5 mb-lg-0">
                <select name className="form-control" id>
                  <option value disabled selected>
                    Select Office
                  </option>
                  <option value />
                </select>
                <small className="form-text text-muted">
                  Filter by <b>Office</b>
                </small>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 mb-5 mb-lg-0">
                <select name className="form-control" id>
                  <option value disabled selected>
                    Select Building
                  </option>
                  <option value />
                </select>
                <small className="form-text text-muted">
                  Filter by <b>Building</b>
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-1  ">
            <button className="btn btn-block btn-light-danger px-3 font-weight-bolder">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
