import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Pagination from "react-js-pagination";
import { Loader } from "../../../../components";
import { getParams, PER_PAGE } from "../../../../config";
import { getApiData } from "../../../../Services";
import { MoveModal } from "./../modals";
export default function Table({ filters }) {
  const [moveModal, setMoveModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getRecords();
  }, [activePage, filters]);

  const getRecords = () => {
    const params = getParams(filters, activePage, PER_PAGE);

    getApiData(`tapri/v1/thermos/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (activePage > 1)
              value = PER_PAGE * activePage - PER_PAGE + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  const changeIsDefault = async (id) => {
    // setLoading(true);
    return await getApiData(`v1/utensils/${id}/`, "delete").then((res) => {
      if (!res.status) {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <MoveModal
        data={selectedRow}
        show={moveModal}
        onHide={() => {
          setMoveModal(false);
        }}
        onSuccess={(message) => {
          toast.success(message);
          getRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <div className="row cus-row">
        <div className="col-lg-12">
          {/*begin::Mixed Widget 14*/}
          <div className="card card-custom   gutter-b card-stretch" style={{}}>
            {/*begin::Body*/}
            <div className="card-body px-0 table-responsive">
              {loading ? (
                <div className="p-4">
                  <Loader />
                </div>
              ) : (
                <>
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-3">#</th>
                        <th>Code</th>
                        <th>Current Owner</th>
                        <th>Capacity</th>
                        <th>Current Inventory</th>
                        <th>Last Filled</th>
                        <th>Product</th>
                        <th>History</th>
                        <th>Last Updated</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length ? (
                        data.map((item) => {
                          return (
                            <tr>
                              <td className="pl-3">
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.sr_no}
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.code}
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.current_owner}
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bolder font-size-md">
                                  {item.capacity}ml
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bolder font-size-md">
                                  {item.current_level}ml
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bolder font-size-md">
                                  {item.last_filled
                                    ? new Date(
                                        item.last_filled
                                      ).toLocaleDateString() +
                                      " " +
                                      new Date(
                                        item.last_filled
                                      ).toLocaleTimeString()
                                    : " - "}
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.product}
                                </span>
                              </td>
                              <td>
                                <a
                                  onClick={() => {
                                    // onOfficeChange("incr", wing, floor);
                                  }}
                                  className="btn btn-sm btn-outline btn-icon btn-outline-dashed btn-outline-danger btn-active-light-danger px-5 "
                                >
                                  <i className="fa fa-plus fs-4 text-danger pe-0" />{" "}
                                </a>
                              </td>
                              <td>
                                <span className="font-weight-bolder font-size-md">
                                  {new Date(
                                    item.updated_at
                                  ).toLocaleDateString() +
                                    " " +
                                    new Date(
                                      item.updated_at
                                    ).toLocaleTimeString()}
                                </span>
                              </td>
                              <td>
                                <button
                                  className="btn  btn-light btn-hover-danger btn-sm"
                                  onClick={() => {
                                    setSelectedRow(item);
                                    setMoveModal(true);
                                  }}
                                >
                                  Move
                                </button>
                                <span class="switch switch-sm">
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        toast.promise(
                                          changeIsDefault(
                                            item.utensil_id,
                                            e.target.checked
                                          ),
                                          {
                                            loading: "Updating...",
                                            success: "Success!",
                                            error: "Failed.",
                                          }
                                        );
                                      }}
                                      defaultChecked={item.is_active}
                                    />
                                    <span></span>
                                  </label>
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="pl-3">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {totalRecords > 0 && (
                    <div className="mt-2 float-right mr-3">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={PER_PAGE}
                        totalItemsCount={totalRecords}
                        pageRangeDisplayed={5}
                        itemClass="d-flex justify-content-between align-items-center flex-wrap"
                        linkClass="btn btn-icon btn-sm border-0 btn-light  btn-hover-primary mr-2 my-1"
                        activeLinkClass="btn btn-icon btn-sm border-0 btn-light  btn-hover-primary mr-2 my-1 active"
                        onChange={(pageNumber) => {
                          setActivePage(pageNumber);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Mixed Widget 14*/}
        </div>
      </div>
    </div>
  );
}
// export default injectIntl(connect(null, auth.actions)(Table));
