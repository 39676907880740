import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { injectIntl } from "react-intl";
import Pagination from "react-js-pagination";
import { connect, useSelector } from "react-redux";
import { Loader } from "../../../../components";
import { getParams, PER_PAGE } from "../../../../config";
import { getApiData } from "../../../../Services";
import * as auth from "./../../../../modules/Auth/_redux/authRedux";
import { WastageModal } from "./../modals";
function Table({ updateShowing, filters }) {
  const { showing } = useSelector((state) => state.auth);
  const [wastageModal, setWastageModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getRecords();
  }, [activePage, filters]);

  useEffect(() => {
    if (showing) {
      setWastageModal(true);
    }
  }, [showing]);

  const getRecords = () => {
    const params = getParams(filters, activePage, PER_PAGE);

    getApiData(`tapri/v1/wastage${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (activePage > 1)
              value = PER_PAGE * activePage - PER_PAGE + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  return (
    <div>
      <WastageModal
        show={wastageModal}
        onHide={() => {
          updateShowing(null);
          setWastageModal(!wastageModal);
        }}
        onSuccess={(message) => {
          setWastageModal(false);
          updateShowing(null);
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <div className="row cus-row">
        <div className="col-lg-12">
          {/*begin::Mixed Widget 14*/}
          <div className="card card-custom   gutter-b card-stretch" style={{}}>
            {/*begin::Body*/}
            <div className="card-body px-0 table-responsive">
              {loading ? (
                <div className="p-4">
                  <Loader />
                </div>
              ) : (
                <>
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-3">#</th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Container</th>
                        <th>Remarks</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length ? (
                        data.map((item) => {
                          return (
                            <tr>
                              <td className="pl-3">
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.sr_no}
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.product}
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bolder font-size-md">
                                  {item.quantity}ml
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.code}
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.remarks}
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bolder font-size-md">
                                  {new Date(
                                    item.created_at
                                  ).toLocaleDateString() +
                                    " " +
                                    new Date(
                                      item.created_at
                                    ).toLocaleTimeString()}
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="pl-3">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {totalRecords > 0 && (
                    <div className="mt-2 float-right mr-3">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={PER_PAGE}
                        totalItemsCount={totalRecords}
                        pageRangeDisplayed={5}
                        itemClass="d-flex justify-content-between align-items-center flex-wrap"
                        linkClass="btn btn-icon btn-sm border-0 btn-light  btn-hover-primary mr-2 my-1"
                        activeLinkClass="btn btn-icon btn-sm border-0 btn-light  btn-hover-primary mr-2 my-1 active"
                        onChange={(pageNumber) => {
                          setActivePage(pageNumber);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Mixed Widget 14*/}
        </div>
      </div>
    </div>
  );
}
export default injectIntl(connect(null, auth.actions)(Table));
