// export const API_URL = 'http://18.116.143.95/ala-cart/api/v1/'
import moment from "moment";
import { getApiData } from "../Services";

export const API_URL = "https://cord3dev.com/dashboard/";
// export const API_URL = "http://18.216.10.204/api/v1/";
// export const MEDIA_DOMAIN = "https://api.aalacart.com";
export const USER_IMAGE =
  "https://chhotu-bucket.s3.ap-south-1.amazonaws.com/users/default.png";

export const PER_PAGE = 20;
export const STRIPE_PUBLISHABLE_KEY =
  "pk_test_FgJ2NUlni0eehEtRDcLOdXvx00NmYpDbHt";
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const numToSSColumn = (num) => {
  let s = "",
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
};

export const getRandomValue = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const validEmail = (email) => {
  const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
  return regex.test(email);
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertDate = (date) => {
  let newDate = "";
  if (date) {
    newDate = new Date(date).toLocaleString();
  }
  return newDate;
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};
export const SelectStyle = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #E4E6EF",
    boxShadow: "none",
    // '&:focused': {
    //   border: '1px solid lime',
    // },
    "&:hover": {
      border: "1px solid #E4E6EF",
    },
  }),
};

export const getStatusColor = (status) => {
  let color = "danger";
  if (status === "IN_PROGRESS") {
    color = "primary";
  } else if (status === "DONE") {
    color = "success";
  } else if (status === "PENDING") {
    color = "warning";
  }
  return color;
};

export const getDaySuffix = (num) => {
  var array = ("" + num).split("").reverse(); // E.g. 123 = array("3","2","1")

  if (array[1] != "1") {
    // Number is not in the teens
    switch (array[0]) {
      case "1":
        return num + "st";
      case "2":
        return num + "nd";
      case "3":
        return num + "rd";
    }
  }

  return num + " th";
};

export const getParams = (filters, currentPage, sizePerPage) => {
  let param = "";
  let offset = (currentPage - 1) * sizePerPage;
  param += `?limit=${sizePerPage}&offset=${offset}`;
  if (filters.search) {
    param += `&query=${filters.search}`;
  }
  if (filters.status) {
    param += `&enabled=${filters.status}`;
  }
  if (filters.status_) {
    param += `&status=${filters.status_}`;
  }
  if (filters.state) {
    param += `&state=${filters.state}`;
  }
  if (filters.city) {
    param += `&city=${filters.city}`;
  }
  if (filters.area) {
    param += `&area=${filters.area}`;
  }
  if (filters.tapri) {
    param += `&tapri=${filters.tapri}`;
  }
  if (filters.type) {
    param += `&type=${filters.type}`;
  }
  if (filters.batchNo) {
    param += `&batch_no=${filters.batchNo}`;
  }
  if (filters.startDate) {
    param += `&start_date=${filters.startDate}`;
  }
  if (filters.endDate) {
    param += `&end_date=${filters.endDate}`;
  }
  if (filters.container) {
    param += `&container=${filters.container}`;
  }

  return param;
};

export const getTapriByArea = async (area) => {
  return getApiData(`v1/tapris/?area=${area}`).then((res) => {
    if (res.status) {
      let tapri = [];
      res.data.map((item) => {
        tapri = [...tapri, { label: item.name, value: item.id }];
      });
      return tapri;
    }
  });
};

export const getAreaByCity = async (city) => {
  return getApiData(`v1/areas/?city=${city}`).then((res) => {
    if (res.status) {
      let area = [];
      res.data.map((item) => {
        area = [...area, { label: item.name, value: item.id }];
      });
      return area;
    }
  });
};

export const getStateList = async () => {
  return getApiData("v1/locations").then((res) => {
    if (res.status) {
      let state = [];
      let location = res.data[0].location;
      Object.keys(location).map((item) => {
        state = [...state, { label: item, value: item, city: location[item] }];
      });
      return state;
    }
  });
};

export const module = {
  options: [],
  header: [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera,
  ],
  dataos: [
    { name: "Windows Phone", value: "Windows Phone", version: "OS" },
    { name: "Windows", value: "Win", version: "NT" },
    { name: "iPhone", value: "iPhone", version: "OS" },
    { name: "iPad", value: "iPad", version: "OS" },
    { name: "Kindle", value: "Silk", version: "Silk" },
    { name: "Android", value: "Android", version: "Android" },
    { name: "PlayBook", value: "PlayBook", version: "OS" },
    { name: "BlackBerry", value: "BlackBerry", version: "/" },
    { name: "Macintosh", value: "Mac", version: "OS X" },
    { name: "Linux", value: "Linux", version: "rv" },
    { name: "Palm", value: "Palm", version: "PalmOS" },
  ],
  databrowser: [
    { name: "Chrome", value: "Chrome", version: "Chrome" },
    { name: "Firefox", value: "Firefox", version: "Firefox" },
    { name: "Safari", value: "Safari", version: "Version" },
    { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
    { name: "Opera", value: "Opera", version: "Opera" },
    { name: "BlackBerry", value: "CLDC", version: "CLDC" },
    { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
  ],
  init: function() {
    var agent = this.header.join(" "),
      os = this.matchItem(agent, this.dataos),
      browser = this.matchItem(agent, this.databrowser);

    return { os: os, browser: browser };
  },
  matchItem: function(string, data) {
    var i = 0,
      j = 0,
      regex,
      regexv,
      match,
      matches,
      version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, "i");
      match = regex.test(string);
      if (match) {
        regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
        matches = string.match(regexv);
        version = "";
        if (matches) {
          if (matches[1]) {
            matches = matches[1];
          }
        }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += matches[j] + ".";
            } else {
              version += matches[j];
            }
          }
        } else {
          version = "0";
        }
        return {
          name: data[i].name,
          version: parseFloat(version),
        };
      }
    }
    return { name: "unknown", version: 0 };
  },
};

export const DateRangeSettings = {
  autoUpdateInput: false,
  ranges: {
    Today: [moment().toDate(), moment().toDate()],
    Yesterday: [
      moment()
        .subtract(1, "days")
        .toDate(),
      moment()
        .subtract(1, "days")
        .toDate(),
    ],
    "Last 7 Days": [
      moment()
        .subtract(6, "days")
        .toDate(),
      moment().toDate(),
    ],
    "Last 30 Days": [
      moment()
        .subtract(29, "days")
        .toDate(),
      moment().toDate(),
    ],
    "This Month": [
      moment()
        .startOf("month")
        .toDate(),
      moment()
        .endOf("month")
        .toDate(),
    ],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .startOf("month")
        .toDate(),
      moment()
        .subtract(1, "month")
        .endOf("month")
        .toDate(),
    ],
  },
};
