import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GeneralDropdown } from "../../../../components";
import { getApiData } from "../../../../Services";

export default function({ show, onHide, onSuccess, onError, list }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [sending, setSending] = useState(false);

  const onSubmit = () => {
    if (selectedProduct) {
      setSending(true);
      const body = {
        product: selectedProduct,
      };

      getApiData("tapri/v1/batch/", "post", body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message);
        } else {
          onError(res.message);
        }
      });
    }
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add New Batch
        </Modal.Title>
        <button className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row">
          <div className="col-lg-12 mb-5 mb-lg-0">
            <label htmlFor className="font-weight-bolder">
              Select Product
            </label>
            <GeneralDropdown
              inModal
              list={list}
              handleChange={(obj) => {
                setSelectedProduct(obj.value);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        <button
          className="btn btn-danger font-weight-bold px-10"
          onClick={onSubmit}
          disabled={selectedProduct && !sending ? false : true}
        >
          Start Batch
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
      </div>
    </Modal>
  );
}
