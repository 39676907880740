import React from 'react'
import './loader.css'
// const Style = {}
export default function Loader() {
  return (
    <>
      {/* <div className="d-flex justify-content-center align-items-center">
        <img src="https://i.gifer.com/YCZH.gif" />
      </div> */}
      {/* <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '20rem' }}
      >
        <div className={Style.sk_chase}>
          <div className={Style.sk_chase_dot}></div>
          <div className={Style.sk_chase_dot}></div>
          <div className={Style.sk_chase_dot}></div>
          <div className={Style.sk_chase_dot}></div>
          <div className={Style.sk_chase_dot}></div>
          <div className={Style.sk_chase_dot}></div>
        </div>
      </div> */}
      <div>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-1 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-1 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
          </div>
          <div className="row">
            <div className="col-sm-1 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
          </div>
          <div className="row">
            <div className="col-sm-1 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
          </div>
          <div className="row">
            <div className="col-sm-1 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
          </div>
          <div className="row">
            <div className="col-sm-1 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
          </div>
          <div className="row">
            <div className="col-sm-1 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
            <div className="col-sm-2 line"></div>
          </div>
        </div>
        {/* <table className="tg table-responsive table">
          <tr>
            <th className="line"></th>
            <th className="line"></th>
            <th className="line"></th>
            <th className="line"></th>
            <th className="line"></th>
            <th className="line"></th>
            <th className="line"></th>
            <th className="line"></th>
          </tr>
          <hr />
          <tr>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
          </tr>
          <tr>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
          </tr>
          <tr>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
          </tr>
          <tr>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
          </tr>
          <tr>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
            <td className="line"></td>
          </tr>
        </table> */}
      </div>
    </>
  )
}
