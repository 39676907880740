import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Loader } from "../../../../components";
import { getApiData } from "../../../../Services";
import { QrScannerModal } from "./../../_modals";

export default function({ show, onHide, onSuccess, onError, data }) {
  const inputRef = useRef();

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [thermosList, setThermosList] = useState([]);

  useEffect(() => {
    if (data.id) {
      getThermosList();
    }
  }, [data]);

  const getThermosList = () => {
    setLoading(true);

    getApiData(`v1/utensils/?type=${data.product_name}&limit=100`).then(
      (res) => {
        setLoading(false);

        if (res.status) {
          setThermosList(res.data);
        }
      }
    );
  };

  const setNativeValue = (element, value) => {
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", { target: element, bubbles: true });
    // React 15
    event.simulated = true;
    // React 16
    let tracker = element._valueTracker;
    if (tracker) {
      tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
  };

  const onSubmit = () => {
    let array = [];
    let totalFillQty = 0;
    thermosList.map((item) => {
      if (item.is_scanned && item.current_qty) {
        totalFillQty += parseFloat(item.current_qty);
        array = [
          ...array,
          { id: item.id, quantity: parseFloat(item.current_qty) },
        ];
      }
    });

    if (array.length) {
      if (totalFillQty > parseFloat(data.remaining_qty)) {
        toast.error("Your filled qty is more than remaining qty.");
      } else {
        setSending(true);

        const body = {
          utensils_filled: array,
        };

        getApiData(`tapri/v1/batch/${data.id}/`, "put", body).then((res) => {
          setSending(false);
          if (res.status) {
            onHide();
            onSuccess(res.message);
          } else {
            if (typeof res.message === "string") {
              onError(res.message);
            } else {
              onError(Object.values(res.message)[0]);
            }
          }
        });
      }
    } else {
      toast.error("Please scan & enter current capacity");
    }
  };

  const fillUtensil = (value_) => {
    const array = [...thermosList];
    let element = {};
    array.map((item, i) => {
      if (item.code === value_) {
        if (item.is_scanned !== 1) {
          item.is_scanned = 1;
          element = item;
          delete array[i];
        } else {
          element.already_scanned = true;
        }
      }
    });
    let filter = array.filter((e) => e);
    // console.log(filter);
    // console.log(element);
    if (element.id) {
      let final = [element, ...filter];
      console.log(final);
      toast.success(`${value_} Utensil scan successfully.`);
      setThermosList(final);
      inputRef.current.value = "";
    } else if (!element.already_scanned) {
      toast.error(`${value_} Utensil is invalid.`);
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fill Utensils{" "}
          <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Batch:</span> {data.batch_id}
          </span>
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3">
        <div className="row">
          <div className="col-md-12 mb-8">
            <span className="label label-lg label-light label-inline font-weight-bolder mr-2">
              <span className="text-dark mr-1">{data.product_name}</span>
            </span>
            <span className="label label-lg label-light label-inline font-weight-bolder">
              Total Output Qty:{" "}
              <span className="text-dark ml-1">{data.output_qty ?? 0}ml</span>{" "}
            </span>
            <span className="label label-lg label-light label-inline font-weight-bolder">
              Remaining Qty:{" "}
              <span className="text-dark ml-1">
                {data.remaining_qty ?? 0}ml
              </span>{" "}
            </span>
            <span className="label label-lg label-light label-inline font-weight-bolder ml-2">
              <span className="text-dark mr-1">{data.product_name}</span>
            </span>
          </div>{" "}
          <div className="col-md-12 text-center mb-5 mb-md-5 d-flex align-items-center">
            <div className="col-md-6">
              <QrScannerModal
                // value={inputRef.current && inputRef.current.value}
                onScan={(code) => {
                  if (code !== inputRef.current.value) {
                    inputRef.current.value = code;
                    fillUtensil(code);
                  }
                }}
              />
            </div>
            <div className="col-md-6">
              <input
                ref={inputRef}
                className="form-control"
                type="text"
                placeholder="Enter thermos code manually"
                // value={value}
                // onChange={(e) => {
                //   setValue(e.target.value);
                // }}
              />
              <button
                className="btn btn-danger font-weight-bold px-10 mt-3"
                onClick={() => {
                  console.log(inputRef.current.value);
                  if (inputRef.current.value) {
                    fillUtensil(inputRef.current.value);
                  } else {
                    toast.error("Please enter thermos code.");
                  }
                }}
              >
                Fill
              </button>
            </div>
          </div>
          <div className="col-md-12 mt-4 table-responsive">
            {loading ? (
              <div className="p-4">
                <Loader />
              </div>
            ) : (
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th className="pl-3">
                      <span className="text-dark-75">#</span>
                    </th>
                    <th>Thermos No</th>
                    <th>Total Capacity</th>
                    <th>Status</th>
                    <th>Action</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {thermosList.length ? (
                    thermosList.map((item, index) => {
                      return (
                        <tr>
                          <td className="pl-3">
                            <span className="font-weight-bolder font-size-sm">
                              {index + 1}
                            </span>
                          </td>
                          <td
                            onClick={() => {
                              inputRef.current.value = item.code;
                            }}
                          >
                            <span className="font-weight-bolder font-size-sm">
                              {item.code}
                            </span>
                          </td>
                          <td>
                            <span className="font-weight-bolder font-size-sm">
                              {item.capacity}ml
                            </span>
                          </td>
                          <td>
                            <td>
                              <a
                                className={`btn btn-icon btn-light-${
                                  item.is_scanned ? "success" : "warning"
                                } btn-hover-${
                                  item.is_scanned ? "success" : "warning"
                                } btn-sm`}
                              >
                                <i
                                  className={
                                    item.is_scanned
                                      ? "flaticon2-check-mark"
                                      : `flaticon2-crisp-icons-1`
                                  }
                                  style={{ fontSize: "11px" }}
                                />
                              </a>
                            </td>
                          </td>

                          <td>
                            {item.is_scanned ? (
                              <input
                                id={`input_${item.code}`}
                                type="number"
                                placeholder="Input Capacity (in ml.)"
                                // defaultValue={item.current_qty ?? ""}
                                className="form-control w-75"
                                onChange={(e) => {
                                  if (e.target.value <= item.capacity) {
                                    // let array = [...thermosList];
                                    item["current_qty"] = e.target.value;
                                    // setThermosList(array);
                                  } else {
                                    var input = document.getElementById(
                                      `input_${item.code}`
                                    );
                                    setNativeValue(input, item.current_qty);
                                  }
                                }}
                              />
                            ) : (
                              <button
                                className="btn btn-sm btn-light-danger font-weight-bolder font-size-sm"
                                onClick={() => {
                                  // setReportModal(true);
                                }}
                              >
                                Report Missing
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="pl-3">
                        No Utensils found for {data.product_name}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-danger font-weight-bold px-10"
          // disabled={!isEnable && !sending ? false : true}
          onClick={onSubmit}
        >
          Submit
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
      </div>
    </Modal>
  );
}
