import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";

export default function({ show, onHide, onSuccess, onError, data, status }) {
  // const [modifiedGroupList, setModifiedGroupList] = useState([]);
  const [qty, setQty] = useState("");
  const [remark, setRemark] = useState("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (data.id) {
      setQty(data.output_qty);
      setRemark(data.remarks);
    } else {
      setQty("");
      setRemark("");
    }
  }, [data]);

  const onSubmit = () => {
    if (qty) {
      const body = {
        output_qty: qty,
      };
      if (remark) {
        body.remarks = remark;
      }
      setSending(true);
      getApiData(`tapri/v1/batch/${data.id}/`, "put", body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message, res.data);
        } else {
          onError(res.message);
        }
      });
    }
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {status === "running" ? "Add " : "View "} Output Qty{" "}
          <span className="label label-lg label-light-danger label-inline font-weight-bolder">
            <span className="text-dark mr-1">Batch:</span> {data.batch_id}
          </span>
          <span className="label label-lg label-light label-inline font-weight-bolder ml-2">
            <span className="text-dark mr-1">{data.product_name}</span>
          </span>
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="row">
          <div className="col-md-12 mb-8"></div>
          <div className="col-md-12 mb-5 mb-md-5">
            <label htmlFor className="font-weight-bolder">
              Quantity
            </label>
            <div className="input-group input-group-solid input-group-lg">
              <input
                type="number"
                className="form-control border-right-0"
                placeholder="Enter Quantity"
                value={qty ? qty : ""}
                onChange={(e) => {
                  setQty(e.target.value);
                }}
                disabled={status !== "running"}
              />
              <div className="input-group-append">
                <span className="input-group-text ">
                  <b>ML</b>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12 ">
            <label htmlFor className="font-weight-bolder">
              Add Remarks
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid "
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              disabled={status !== "running"}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
        {status === "running" && (
          <button
            type="button"
            className="btn btn-danger font-weight-bold px-10"
            onClick={onSubmit}
            disabled={qty && !sending ? false : true}
          >
            Add
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        )}
      </div>
    </Modal>
  );
}
