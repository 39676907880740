import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { getApiData } from "../../Services";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function() {
  const { authToken } = useSelector((state) => state.auth);

  // const history = useHistory();

  const [filters, setFilters] = useState({
    search: "",
    type: "",
  });
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    getGroupList();
  }, []);

  const getGroupList = () => {
    getApiData("v1/groups", "get", {}, true, authToken).then((res) => {
      if (res.status) {
        let list = [];
        res.data.map((item) => {
          if (
            item.name !== "customer" &&
            item.name !== "rider" &&
            item.name !== "chhotu"
          ) {
            list = [...list, { label: item.name, value: item.id }];
          }
        });
        setGroupList(list);
      }
    });
  };

  return (
    <>
      <div>
        <Card className="card-spaces">
          <CardBody className="p-4">
            <TableFilter
              groupList={groupList}
              setSearchString={(value) => {
                let obj = { ...filters };
                obj.search = value;
                setFilters(obj);
              }}
              setType={(value) => {
                let obj = { ...filters };
                obj.type = value;
                setFilters(obj);
              }}
              onClearFilter={() => {
                setFilters({
                  search: "",
                  filter: "",
                });
              }}
            />
          </CardBody>
        </Card>
        <Card>
          <Table filters={filters} groupList={groupList} />
        </Card>
      </div>
    </>
  );
}
