import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function() {
  const [filters, setFilters] = useState({
    search: "",
    filter: "",
  });

  useEffect(() => {
    // getList('marketplaces')
  }, []);

  return (
    <>
      <div>
        <Card className="card-spaces">
          <CardBody className="p-4">
            <TableFilter
              setSearchString={(value) => {
                let obj = { ...filters };
                obj.search = value;
                setFilters(obj);
              }}
              setFilterValue={(value) => {
                let obj = { ...filters };
                obj.filter = value;
                setFilters(obj);
              }}
              onClearFilter={() => {
                setFilters({
                  search: "",
                  filter: "",
                });
              }}
            />
          </CardBody>
        </Card>
        <Card>
          <Table filters={filters} />
        </Card>
      </div>
    </>
  );
}
