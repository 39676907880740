import React, { useState } from "react";
import { GeneralDropdown } from "../../../../components";

export default function(props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);

  return (
    <div className="row">
      <div className="col-md-2">
        <GeneralDropdown
          clearFilter={clearFilter}
          selectedOption={props.value.state ? props.value.state : null}
          list={props.stateList}
          handleChange={(obj) => {
            props.onStateSelect(obj);
          }}
        />
        <small className="form-text text-muted">
          Filter by <b>State</b>
        </small>
      </div>
      <div className="col-md-3">
        <GeneralDropdown
          clearFilter={clearFilter}
          selectedOption={props.value.city ? props.value.city : null}
          list={props.cityList}
          handleChange={(obj) => {
            props.onCitySelect(obj.value);
          }}
        />
        <small className="form-text text-muted">
          Filter by <b>City</b>
        </small>
      </div>
      <div className="col-md-3">
        <GeneralDropdown
          clearFilter={clearFilter}
          list={props.areaList}
          selectedOption={props.value.area ? props.value.area : null}
          handleChange={(obj) => {
            props.onAreaSelect(obj.value);
          }}
        />
        <small className="form-text text-muted">
          Filter by <b>Area</b>
        </small>
      </div>
      <div className="col-md-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            if (e.target.value.length > 2) {
              setIsSearching(true);
              props.setSearchString(e.target.value);
            } else {
              if (isSearching) {
                props.setSearchString("");
                setIsSearching(false);
              }
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setIsSearching(true);
              props.setSearchString(search);
            }
          }}
        />
        <small className="form-text text-muted">
          <b>Search</b> in all fields
        </small>
      </div>
      <div className="col-lg-1 ">
        <button
          className="btn btn-block btn-light-danger px-6 font-weight-bolder"
          onClick={() => {
            props.onClearFilter();
            setSearch("");
            setClearFilter(Math.random());
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
}
