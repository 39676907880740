import React from "react";
import { Modal } from "react-bootstrap";

export default function({ show, onHide, data }) {
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          View Utensil
        </Modal.Title>
        <button className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th>#</th>
                      <th>Code</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.utensils && data.utensils.length ? (
                      data.utensils.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <span className="text-dark-75 font-weight-bolder font-size-md">
                                {index + 1}
                              </span>
                            </td>
                            <td>
                              <span className="text-dark-75 font-weight-bolder font-size-md">
                                {item.code}
                              </span>
                            </td>
                            <td>
                              <span className="font-weight-bolder font-size-sm">
                                {item.quantity}
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="pl-3">No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0 pt-0">
        <button
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
