import React, { useState } from "react";
import { GeneralDropdown } from "../../../../components";
export default function(props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [clearFilter, setClearFilter] = useState(null);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-custom gutter-b card-spaces">
            <div className="card-body p-4">
              <div className="row cus-filter-column">
                <div className="col-lg-3 col-md-6 col-sm-6 mb-5 mb-lg-0">
                  <GeneralDropdown
                    clearFilter={clearFilter}
                    list={props.productList}
                    handleChange={(obj) => {
                      props.setTypeValue(obj.value);
                    }}
                  />
                  <small className="form-text text-muted">
                    Filter by <b>Type</b>
                  </small>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-5 mb-lg-0">
                  <div className="input-group" id>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (e.target.value.length > 2) {
                          setIsSearching(true);
                          props.setSearchString(e.target.value);
                        } else {
                          if (isSearching) {
                            props.setSearchString("");
                            setIsSearching(false);
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsSearching(true);
                          props.setSearchString(search);
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-white">
                        <i className="la la-search" />
                      </span>
                    </div>
                  </div>
                  <small className="form-text text-muted">
                    Filter by <b>Search</b>
                  </small>
                </div>
                <div className="col-lg-1 ">
                  <button
                    className="btn btn-block btn-light-danger px-6 font-weight-bolder"
                    onClick={() => {
                      props.onClearFilter();
                      setSearch("");
                      setClearFilter(Math.random());
                      window.$("#date-range").val("");
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
