import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GeneralDropdown } from "../../../components";
import { getApiData } from "../../../Services";

export default function({ show, onHide, data, onSuccess, onError, groupList }) {
  const [sending, setSending] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [group, setGroup] = useState("");

  useEffect(() => {
    // console.log(data);
    if (data.id) {
      setUsername(data.username);
      setEmail(data.email);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setPassword("Not_Allowed");
      setGroup(data.group_id);
    } else {
      setUsername("");
      setEmail("");
      setFirstName("");
      setLastName("");
      setPassword("");
      setGroup(null);
    }
  }, [data]);

  const onSubmit = () => {
    if (username && password && email && firstName && group) {
      setSending(true);
      const body = {
        username: username,
        email: email,
        first_name: firstName,
        last_name: lastName,
        group: group,
      };

      if (!data.id) {
        body.password = password;
      }

      const api = data.id ? `v1/users/${data.id}/` : `v1/users/`;
      const method = data.id ? "put" : "post";

      getApiData(api, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message, res.data);
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError(Object.values(res.message)[0]);
          }
        }
      });
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {data.id ? "Edit" : "Add"} User
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                value={username}
                disabled={data.id}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                value={password}
                disabled={data.id}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Group</label>
              <GeneralDropdown
                selectedOption={group}
                inModal
                list={groupList}
                disabled={data.id}
                // selectedOption={selectedState}
                handleChange={(obj) => {
                  if (obj) setGroup(obj.value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary px-5"
          onClick={onSubmit}
          disabled={
            username && password && email && firstName && group && !sending
              ? false
              : true
          }
        >
          Save
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
