/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
// import { useSelector } from 'react-redux'

export function AsideMenuList({ layoutProps }) {
  // const { authToken } = useSelector((state) => state.auth)
  // let user = localStorage.getItem('user')
  // user = JSON.parse(user)
  const { user } = useSelector((state) => state.auth);

  let isAdmin = user && user.user_type === "admin" ? true : false;

  // const BETA = <small style={{ marginLeft: "5px" }}> (BETA)</small>;

  // console.log(user)

  // alert(user.user_type)

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {isAdmin ? (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">Admin</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Devices/TV1.svg")}
                  />
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li> */}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/dashboard",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Devices/TV1.svg")}
                  />
                </span>
                <span className="menu-text">Dashboard</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/dashboard/sales-analytics",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/dashboard/sales-analytics"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Sales Analytics</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/dashboard/customer-analytics",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/dashboard/customer-analytics"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Customer Analytics</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/master",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/master">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/CMD.svg")} />
                </span>
                <span className="menu-text">Master Tables</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/building",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/building"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Building</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/building-request",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/building-request"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Building Request</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/tapri",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/tapri"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Tapri</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/chhotu",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/chhotu"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Chhotu</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/rider",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/rider"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Rider</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/area",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/area"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Area</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/spot",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/spot"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Spot</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/utensils",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/utensils"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Utensils</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/products",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/products"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Products</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/ingredients",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/ingredients"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Ingredients</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            <li
              className={`menu-item ${getMenuItemActive("/users", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/users">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  />
                </span>
                <span className="menu-text">Users</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/customers", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/customers">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  />
                </span>
                <span className="menu-text">Customers</span>
              </NavLink>
            </li>
            {/* <li
              className={`menu-item ${getMenuItemActive(
                "/live-location",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/live-location">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  />
                </span>
                <span className="menu-text">Live Location</span>
              </NavLink>
            </li> */}
          </>
        ) : (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">USER</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/batch", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/batch">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Box3.svg")}
                  />
                </span>
                <span className="menu-text">Batch</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/deliver", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/deliver">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Map/Direction2.svg")}
                  />
                </span>
                <span className="menu-text">Deliver</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/collect", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/collect">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Bucket.svg")}
                  />
                </span>
                <span className="menu-text">Collect</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/ready-to-wash",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/ready-to-wash">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Electric/Washer.svg")}
                  />
                </span>
                <span className="menu-text">Ready to Wash</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/washing", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/washing">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Electric/Washer.svg")}
                  />
                </span>
                <span className="menu-text">Washing</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/wastage", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/wastage">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
                <span className="menu-text">Wastage</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/live-orders",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/live-orders">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")}
                  />
                </span>
                <span className="menu-text">Live Orders</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/inventory", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/inventory">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Food/Coffee1.svg")}
                  />
                </span>
                <span className="menu-text">Inventory</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/thermos", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/thermos">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Electric/Kettle.svg")}
                  />
                </span>
                <span className="menu-text">Thermos</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/issue", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/issue">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Question-circle.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Issue</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/chhotu-issue",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/chhotu-issue">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Question-circle.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Chhotu Issue</span>
              </NavLink>
            </li>
            {/* <li
              className={`menu-item ${getMenuItemActive("/chhotu", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/chhotu">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  />
                </span>
                <span className="menu-text">Chhotu</span>
              </NavLink>
            </li> */}
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
