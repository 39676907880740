import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GeneralDropdown } from "../../../../components";
import { getApiData } from "../../../../Services";

export default function({
  show,
  onHide,
  onSuccess,
  onError,
  data,
  productList,
  tapriList,
}) {
  const [sending, setSending] = useState(false);
  const [code, setCode] = useState("");
  const [type, setType] = useState("");
  const [size, setSize] = useState("");
  const [capacity, setCapacity] = useState("");
  const [selectedTapri, setSelectedTapri] = useState(null);

  useEffect(() => {
    if (data.id) {
      console.log(data);
      setCode(data.code);
      setType(data.type);
      setSize(data.size);
      setCapacity(data.capacity);
      setSelectedTapri(data.owner_id);
    } else {
      setCode("");
      setType("");
      setSize("");
      setCapacity("");
      setSelectedTapri(null);
    }
  }, [data]);

  const onSubmit = () => {
    if (code && type && size && capacity) {
      setSending(true);

      const body = {
        code: code,
        type: type,
        size: size,
        capacity: capacity,
      };

      if (selectedTapri.value) {
        body.tapri = selectedTapri.value;
      }

      let method = data.id ? "put" : "post";
      let param = data.id ? data.id + "/" : "";

      getApiData(`v1/utensils/${param}`, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message, res.data);
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError("Something went wrong!");
          }
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Utensil</Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 form-group">
            <label>Code</label>
            <input
              className="form-control"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 form-group">
            <label>Type</label>
            <GeneralDropdown
              selectedOption={type}
              inModal
              list={productList}
              handleChange={(obj) => {
                console.log(obj.value);
                setType(obj.value);
              }}
            />
          </div>
          <div className="col-md-12 form-group">
            <label>Size</label>
            <input
              className="form-control"
              value={size}
              onChange={(e) => {
                setSize(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 form-group">
            <label>
              Capacity <small>(in ml.)</small>
            </label>
            <input
              className="form-control"
              value={capacity}
              onChange={(e) => {
                setCapacity(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 form-group">
            <label>Select Tapri</label>
            <GeneralDropdown
              selectedOption={selectedTapri}
              list={tapriList}
              inModal
              handleChange={(obj) => {
                setSelectedTapri(obj);
              }}
              disabled={data.id}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary px-5"
          onClick={onSubmit}
          disabled={
            code && type && size && capacity && selectedTapri && !sending
              ? false
              : true
          }
        >
          Submit
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
