import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {
  headerSortingClasses,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../_metronic/_helpers";
import {
  CardBody,
  CardHeader,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import { DeleteModal, Loader } from "../../../../components";
import { PER_PAGE, sizePerPageList } from "../../../../config";
import { getApiData } from "../../../../Services";
import { IngredientModal } from "../Modal";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ingredientModal, setIngredientModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getParams = async () => {
    let param = "";
    if (props.filters) {
      let search = props.filters.search;
      let type = props.filters.type;
      let offset = (currentPage - 1) * sizePerPage;
      param += `?limit=${sizePerPage}&offset=${offset}&query=${search}&type=${type}`;
    }
    return param;
  };

  const getTableRecords = async () => {
    const params = await getParams();

    await getApiData(`v1/ingredients/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      editable: false,
      formatter: (_, row) => <span>{row.sr_no}</span>,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      editable: false,
      sort: true,
      headerStyle: () => {
        return { minWidth: "150px" };
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "measurement_unit",
      text: "Unit",
      editable: false,
      sort: true,
      headerStyle: () => {
        return { minWidth: "120px" };
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      editable: false,
      formatter: (_, row) => (
        <>
          <button
            className="btn btn-icon btn-light btn-hover-secondary btn-sm"
            onClick={() => {
              setIngredientModal(true);
              setSelectedRow(row);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-secondary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </button>
          <> </>

          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => {
              setSelectedRow(row);
              setDeleteModal(true);
            }}
            // disabled={true}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </>
      ),
      // classes: 'text-right pr-0',
      // headerClasses: 'text-right pr-3',
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };
  return (
    <>
      <CardHeader className="px-0 mt-4 custom-card-header">
        <div className="row col-sm-12 pr-0 ">
          <div>
            <a
              className="btn btn-primary float-right"
              onClick={() => {
                setSelectedRow({});
                setIngredientModal(true);
              }}
            >
              Add New Ingredient
            </a>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <DeleteModal
          show={deleteModal}
          api="v1/ingredients"
          title="Delete Ingredient"
          message="Are you sure to permanently delete this ingredient?"
          onHide={() => setDeleteModal(!deleteModal)}
          id={selectedRow.id}
          onSuccess={(message) => {
            toast.success(message);
            getTableRecords();
          }}
          onFailed={(message) => {
            toast.error(message);
          }}
        />
        <IngredientModal
          show={ingredientModal}
          onHide={() => setIngredientModal(!ingredientModal)}
          data={selectedRow}
          onSuccess={(message) => {
            setIngredientModal(false);
            toast.success(message);
            getTableRecords();
          }}
          onError={(message) => {
            toast.error(message);
          }}
        />
        {loading ? (
          <Loader />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    onTableChange={() => {}}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    id="userTable"
                    bootstrap4
                    keyField="id"
                    data={data === null ? [] : data}
                    columns={columns}
                    // cellEdit={cellEditFactory({
                    //   mode: "click",
                    //   blurToSave: true,
                    // })}
                    noDataIndication={() => {
                      return "No Data to Display";
                    }}
                    defaultSorted={[{ dataField: "id", order: "asc" }]}
                    {...paginationTableProps}
                  >
                    {/* <PleaseWaitMessage entities={data} /> */}
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )}
      </CardBody>
    </>
  );
}
