import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "../../../components";
import { getApiData } from "../../../Services";
import { HelpModal as ReportModal, ScanModal } from "../_modals";
import { Spinner } from "react-bootstrap";

export default function(props) {
  const history = useHistory();
  const location = useLocation();

  const [reportModal, setReportModal] = useState(false);
  const [scanModal, setScanModal] = useState(false);
  const [riderName, setRiderName] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (props.match && props.match.params) {
      getUtensils();
    }
  }, [props]);

  useEffect(() => {
    if (location.state && location.state.riderName) {
      setRiderName(location.state.riderName);
    } else {
      history.push("/collect");
    }
  }, [location]);

  const getUtensils = () => {
    getApiData(
      `tapri/v1/user/utensil/list?user_id=${props.match.params.id}&type=collect`
    ).then((res) => {
      setLoading(false);
      if (res.status) {
        if (res.data.length) {
          setData(res.data);
        } else {
          history.push("/collect");
          toast.success("utensil collected successfully");
        }
      }
    });
  };

  return (
    <div>
      <ScanModal
        api="tapri/v1/utensil/request/receive"
        show={scanModal}
        onHide={() => setScanModal(!scanModal)}
        onSuccess={(message) => {
          getUtensils();
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <ReportModal
        show={reportModal}
        onHide={() => setReportModal(!reportModal)}
        onSuccess={(message) => {
          setReportModal(false);
          toast.success(message);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <div
        className="card card-custom gutter-b card-spaces"
        style={{
          MinHeight: "100px",
          backgroundColor: "#fff",
          //   backgroundPosition: "left bottom",
          //   backgroundSize: "auto 100%",
          //   BackgroundImage: "url(assets/media/svg/humans/custom-2.svg)",
          //   backgroundRepeat: "no-repeat",
        }}
      >
        <div className="card-body p-4">
          <div className="row">
            {/*  <div class=" col-md-3 offset-md-1">
                                      <h3 class="mt-8">Welcome back, <span class="text-danger font-weight-bolder">Rahul</span></h3>
                                  </div> */}
            <div className="col-md-12">
              <div className="row mt-0">
                <div className="col-md-3">
                  <div className="d-flex align-items-center mr-2">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-45 symbol-light-light mr-4 flex-shrink-0">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                          {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Food\Coffee1.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M8.40093151,17 L5,17 C3.34314575,17 2,15.1568542 2,13.5 C2,11.8431458 3.34314575,10 5,10 L6,10 L6,8 L21,8 L21,11.5 C21,15.6421356 17.6421356,19 13.5,19 C11.5309185,19 9.73907026,18.2411745 8.40093151,17 Z M6.86504659,15 C6.38614142,14.0940164 6.08736465,13.0781211 6.01640228,12 L5,12 C4.44771525,12 4,12.9477153 4,13.5 C4,14.0522847 4.44771525,15 5,15 L6.86504659,15 Z"
                                fill="#000000"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x={6}
                                y={21}
                                width={15}
                                height={2}
                                rx={1}
                              />
                              <path
                                d="M8.11576273,0 L9.27322553,1.15267194 C8.41777098,2.01168201 8.42065331,3.40153019 9.27966338,4.25698473 C9.35322262,4.3302395 9.4318859,4.39818368 9.51506091,4.46030566 L10,4.82249831 L9.02250371,6.13126634 L8.53756462,5.76907368 C8.39249331,5.66072242 8.25529121,5.54221626 8.12699144,5.41444753 C6.62873232,3.92238985 6.62370505,1.49825912 8.11576273,0 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M13.1157627,1 L14.2732255,2.15267194 C13.417771,3.01168201 13.4206533,4.40153019 14.2796634,5.25698473 C14.3532226,5.3302395 14.4318859,5.39818368 14.5150609,5.46030566 L15,5.82249831 L14.0225037,7.13126634 L13.5375646,6.76907368 C13.3924933,6.66072242 13.2552912,6.54221626 13.1269914,6.41444753 C11.6287323,4.92238985 11.6237051,2.49825912 13.1157627,1 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M18.1157627,0 L19.2732255,1.15267194 C18.417771,2.01168201 18.4206533,3.40153019 19.2796634,4.25698473 C19.3532226,4.3302395 19.4318859,4.39818368 19.5150609,4.46030566 L20,4.82249831 L19.0225037,6.13126634 L18.5375646,5.76907368 C18.3924933,5.66072242 18.2552912,5.54221626 18.1269914,5.41444753 C16.6287323,3.92238985 16.6237051,1.49825912 18.1157627,0 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                    </div>
                    {/*end::Symbol*/}
                    {/*begin::Title*/}
                    <div>
                      <div className="font-size-h4 text-dark-75 font-weight-bolder">
                        1.0L | 2.5L
                      </div>
                      <div className="font-size-sm text-danger font-weight-bold mt-1">
                        Ragular Tea
                      </div>
                    </div>
                    {/*end::Title*/}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mr-2">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-45 symbol-light-light mr-4 flex-shrink-0">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                          {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Food\Coffee2.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M8,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,16 C22,18.209139 20.209139,20 18,20 L11,20 C8.790861,20 7,18.209139 7,16 L7,5 C7,4.44771525 7.44771525,4 8,4 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                              <path
                                d="M7,7 L7,9 L5,9 C4.44771525,9 4,9.44771525 4,10 L4,12 C4,12.5522847 4.44771525,13 5,13 L7,13 L7,15 L5,15 C3.34314575,15 2,13.6568542 2,12 L2,10 C2,8.34314575 3.34314575,7 5,7 L7,7 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x={18}
                                y={7}
                                width={2}
                                height={8}
                                rx={1}
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                    </div>
                    {/*end::Symbol*/}
                    {/*begin::Title*/}
                    <div>
                      <div className="font-size-h4 text-dark-75 font-weight-bolder">
                        1.0L | 2.5L
                      </div>
                      <div className="font-size-sm text-danger font-weight-bold mt-1">
                        Coffee
                      </div>
                    </div>
                    {/*end::Title*/}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mr-2">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-45 symbol-light-light mr-4 flex-shrink-0">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                          {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Food\Beer.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M22,7 L22,9 L19,9 C18.4477153,9 18,9.44771525 18,10 L18,13 C18,13.5522847 18.4477153,14 19,14 L22,14 L22,16 L19,16 C17.3431458,16 16,14.6568542 16,13 L16,10 C16,8.34314575 17.3431458,7 19,7 L22,7 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.7"
                                transform="translate(19.000000, 11.500000) scale(-1, 1) translate(-19.000000, -11.500000) "
                              />
                              <path
                                d="M4.75777452,5 C5.56503586,3.79401426 6.93979195,3 8.5,3 C10.0602081,3 11.4349641,3.79401426 12.2422255,5 L4.75777452,5 Z"
                                fill="#000000"
                                opacity={15}
                              />
                              <path
                                d="M7,5 C7,2.790861 8.790861,1 11,1 C13.209139,1 15,2.790861 15,5 L7,5 Z"
                                fill="#000000"
                                opacity={15}
                              />
                              <path
                                d="M12.0500091,5 C12.2816442,3.85887984 13.290521,3 14.5,3 C15.709479,3 16.7183558,3.85887984 16.9499909,5 L12.0500091,5 Z"
                                fill="#000000"
                                opacity={15}
                              />
                              <path
                                d="M8,8 L8,8 C8.55228475,8 9,8.44771525 9,9 L9,18 C9,18.5522847 8.55228475,19 8,19 L8,19 C7.44771525,19 7,18.5522847 7,18 L7,9 C7,8.44771525 7.44771525,8 8,8 Z M13,8 L13,8 C13.5522847,8 14,8.44771525 14,9 L14,18 C14,18.5522847 13.5522847,19 13,19 L13,19 C12.4477153,19 12,18.5522847 12,18 L12,9 C12,8.44771525 12.4477153,8 13,8 Z M4.06055214,5 L16.9394479,5 C17.4917326,5 17.9394479,5.44771525 17.9394479,6 C17.9394479,6.01958668 17.9388724,6.03916914 17.9377222,6.05872202 L17.1107386,20.117444 C17.0485547,21.1745693 16.1731425,22 15.1141898,22 L5.88581016,22 C4.82685754,22 3.95144525,21.1745693 3.88926141,20.117444 L3.06227777,6.05872202 C3.02984649,5.50739031 3.4504984,5.0341569 4.00183012,5.00172563 C4.021383,5.00057546 4.04096546,5 4.06055214,5 Z"
                                fill="#000000"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                    </div>
                    {/*end::Symbol*/}
                    {/*begin::Title*/}
                    <div>
                      <div className="font-size-h4 text-dark-75 font-weight-bolder">
                        1.0L | 2.5L
                      </div>
                      <div className="font-size-sm text-danger font-weight-bold mt-1">
                        Cold Coffee
                      </div>
                    </div>
                    {/*end::Title*/}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mr-2">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-45 symbol-light-light mr-4 flex-shrink-0">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                          {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Food\Coffee1.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M8.40093151,17 L5,17 C3.34314575,17 2,15.1568542 2,13.5 C2,11.8431458 3.34314575,10 5,10 L6,10 L6,8 L21,8 L21,11.5 C21,15.6421356 17.6421356,19 13.5,19 C11.5309185,19 9.73907026,18.2411745 8.40093151,17 Z M6.86504659,15 C6.38614142,14.0940164 6.08736465,13.0781211 6.01640228,12 L5,12 C4.44771525,12 4,12.9477153 4,13.5 C4,14.0522847 4.44771525,15 5,15 L6.86504659,15 Z"
                                fill="#000000"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x={6}
                                y={21}
                                width={15}
                                height={2}
                                rx={1}
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                    </div>
                    {/*end::Symbol*/}
                    {/*begin::Title*/}
                    <div>
                      <div className="font-size-h4 text-dark-75 font-weight-bolder">
                        1.0L | 2.5L
                      </div>
                      <div className="font-size-sm text-danger font-weight-bold mt-1">
                        Green Tea
                      </div>
                    </div>
                    {/*end::Title*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row cus-row">
        <div className="col-lg-12">
          {/*begin::Mixed Widget 14*/}
          <div className="card card-custom   gutter-b card-stretch" style={{}}>
            <div className="card-header" style={{ minHeight: "60px" }}>
              <h3 className="card-title">
                Please collect below thermos from
                <b className="ml-1"> {riderName}</b>
              </h3>
              <button
                className="ml-2 my-auto btn btn-sm btn-light-success px-5 font-weight-bolder font-size-lg"
                onClick={() => {
                  setScanModal(true);
                }}
                // style={{ width: "200px", height: "50px" }}
              >
                <i className="flaticon-photo-camera" />
                Scan to collect
              </button>
            </div>
            {/*begin::Body*/}
            <div className="card-body px-0 pt-0 table-responsive">
              {loading ? (
                <div className="p-4">
                  <Loader />
                </div>
              ) : (
                <div>
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center mb-0">
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-3">Thermos No.</th>
                        <th>Product</th>
                        <th>Capacity</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length ? (
                        data.map((item) => {
                          return (
                            <tr>
                              <td className="pl-3">
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.code}
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.product}
                                </span>
                              </td>
                              <td>
                                <span className="text-dark-75 font-weight-bolder font-size-md">
                                  {item.capacity}ml
                                </span>
                              </td>
                              <td>
                                <a className="btn btn-icon btn-light-warning btn-hover-warning btn-sm">
                                  <i
                                    className="flaticon2-crisp-icons-1"
                                    style={{ FontSize: "11px" }}
                                  />
                                </a>
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-light-danger font-weight-bolder font-size-sm"
                                  onClick={() => {
                                    setReportModal(true);
                                  }}
                                >
                                  Report Missing
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/*end::Body*/}
            <div className="card-footer py-4">
              <div className="row">
                <div className="col-md-10">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid font-weight-bold"
                    placeholder="Add Remarks"
                  />
                </div>
                <div className="col-md-2 ">
                  <button
                    className="btn btn-block btn-danger font-weight-bolder btn-lg"
                    onClick={() => {
                      setSending(true);
                      getApiData(
                        `tapri/v1/user/utensil/list?user_id=${props.match.params.id}&type=collect`
                      ).then((res) => {
                        setSending(false);
                        if (res.status) {
                          if (res.data.length) {
                            toast.error("Utensil haven't collected yet.");
                          } else {
                            toast.success("Utensil delivered successfully.");
                            history.push("/collect");
                          }
                        } else {
                          toast.error(res.message);
                        }
                      });
                    }}
                    disabled={sending}
                  >
                    Done
                    {sending && (
                      <Spinner animation="border" size="sm" className="ml-1" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*end::Mixed Widget 14*/}
        </div>
      </div>
    </div>
  );
}
