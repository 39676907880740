import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import { GeneralDropdown } from "../../components";
import { numToSSColumn } from "../../config";
import { getApiData } from "../../Services";

export default function({ location }) {
  const history = useHistory();

  const [scrollWing, setScrollWing] = useState(null);
  const [buildingId, setBuildingId] = useState(null);
  const [buildingName, setBuildingName] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [buildingAddr, setBuildingAddr] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [tapriList, setTapriList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedTapri, setSelectedTapri] = useState(null);
  const [buildingObj, setBuildingObj] = useState({});
  const [buildingSaving, setBuildingSaving] = useState(false);
  const [wingSaving, setWingSaving] = useState(false);

  useEffect(() => {
    getStateList();
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude);
          setLon(position.coords.longitude);
          // const latLong = `${position.coords.latitude.toFixed(
          //   5
          // )},${position.coords.longitude.toFixed(5)}`;
          // console.log("latLong");
          // console.log(latLong);
        },
        (error) => {
          console.log(error);
          toast.error("Location permission issue");
        }
      );
    }
  };

  useEffect(() => {
    if (location.state && location.state.id) {
      const state = location.state;
      console.log(state);
      setBuildingId(state.id);
      getBuildingDetails(state.id);
      setBuildingName(state.name);
      setBuildingAddr(state.address);
    }
  }, [location]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity && selectedCity.value) {
      getAreaByCity(selectedCity.value);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedArea && selectedArea.value) {
      getTapriByArea(selectedArea.value);
    }
  }, [selectedArea]);

  useEffect(() => {
    if (scrollWing) {
      const divElement = document.getElementById(scrollWing);
      console.log(scrollWing);
      if (divElement) {
        divElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollWing(null);
    }
  }, [scrollWing]);

  const getBuildingDetails = (id) => {
    getApiData(`v1/buildings/${id}/`).then((res) => {
      if (res.status) {
        console.log(res.data.floors_and_wings);
        try {
          let latLon = res.data.location;
          latLon = latLon.split(",");
          setLat(latLon[0]);
          setLon(latLon[1]);
          // console.log(latLon);
        } catch (error) {}
        setBuildingObj(res.data.floors_and_wings);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const getAreaByCity = (city) => {
    getApiData(`v1/areas/?city=${city}`).then((res) => {
      if (res.status) {
        let area = [];
        res.data.map((item) => {
          area = [...area, { label: item.name, value: item.id }];
        });
        setAreaList(area);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const getTapriByArea = (area) => {
    getApiData(`v1/tapris/?area=${area}`).then((res) => {
      if (res.status) {
        let tapri = [];
        console.log(res.data);
        res.data.map((item) => {
          console.log(item);
          tapri = [...tapri, { label: item.name, value: item.id }];
        });
        console.log(tapri);
        setTapriList(tapri);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  useEffect(() => {
    if (stateList.length && location.state) {
      stateList.map((item) => {
        // console.log(item);
        if (item.value === location.state.state) {
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList]);

  useEffect(() => {
    if (cityList.length && location.state) {
      cityList.map((item) => {
        // console.log(item);
        if (item.value === location.state.city) {
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList]);

  useEffect(() => {
    if (areaList.length && location.state) {
      areaList.map((item) => {
        if (item.value === location.state.area) {
          console.log(item);
          setSelectedArea(item);
          return;
        }
      });
    }
  }, [areaList]);

  useEffect(() => {
    if (tapriList.length && location.state) {
      tapriList.map((item) => {
        if (item.value === location.state.tapri) {
          setSelectedTapri(item);
          return;
        }
      });
    }
  }, [tapriList]);

  const getStateList = () => {
    getApiData("v1/locations").then((res) => {
      if (res.status) {
        let state = [];
        let location = res.data[0].location;
        Object.keys(location).map((item) => {
          state = [
            ...state,
            { label: item, value: item, city: location[item] },
          ];
        });
        setStateList(state);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const onFloorChange = (operation, wing) => {
    let newObj = { ...buildingObj };
    let floorLength = Object.keys(newObj[wing]).length;
    // console.log("Wing : ", wing);
    // console.log("floorLength : ", floorLength);
    if (operation === "incr") {
      newObj[wing] = { ...newObj[wing], [floorLength + 1]: [] };
    } else {
      let keys = Object.keys(newObj[wing]);
      if (keys.length > 1) {
        delete newObj[wing][keys[keys.length - 1]];
      }
    }

    // console.log(newObj);
    setBuildingObj(newObj);
  };

  const onOfficeChange = (operation, wing, floor, index) => {
    let newObj = { ...buildingObj };
    let office = newObj[wing][floor];

    // console.log("officeLength : " + office.length);
    // console.log(newObj[wing][floor]);
    // console.log("operation : " + operation);
    // console.log("wing : " + wing);
    // console.log("floor : " + floor);
    if (operation === "incr") {
      // if (office.length < 10) {
      newObj[wing][floor] = [...office, ""];
      // }
    } else {
      office.splice(index, 1);
      newObj[wing][floor] = office;
    }

    // console.log(newObj);
    setBuildingObj(newObj);
  };

  const onWingChange = (operation) => {
    let newObj = { ...buildingObj };
    let wingLength = Object.keys(newObj).length;
    let scroll = wingLength;
    if (operation === "incr") {
      wingLength = wingLength + 1;
      let wingName = numToSSColumn(wingLength);
      newObj[wingName] = { 1: [] };
    } else {
      scroll = scroll - 2;
      let keys = Object.keys(newObj);
      delete newObj[keys[keys.length - 1]];
    }
    setScrollWing(`wing_${scroll}`);
    // console.log(newObj);
    setBuildingObj(newObj);
  };

  const saveBuilding = () => {
    const latLong = `${lat},${lon}`;
    let body = {
      name: buildingName,
      area: selectedArea ? selectedArea.value : "",
      address: buildingAddr,
      location: latLong,
      tapri: selectedTapri ? selectedTapri.value : "",
    };

    let apiURL = "v1/buildings/";
    if (buildingId) {
      apiURL = `v1/buildings/${buildingId}/`;
    }
    setBuildingSaving(true);

    getApiData(apiURL, buildingId ? "put" : "post", body).then((res) => {
      setBuildingSaving(false);

      if (res.status) {
        if (res.data.building_id) {
          setBuildingId(res.data.building_id);
        }
        toast.success(res.message);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      } else {
        if (typeof res.message === "string") {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong!");
        }
      }
    });
  };

  const hasDuplicates = (arr) => {
    return arr.some(function(item) {
      return arr.indexOf(item) !== arr.lastIndexOf(item);
    });
  };

  const saveWing = () => {
    // setWingSaving(true);

    let message = "";
    Object.keys(buildingObj).map((item) => {
      let array = [];
      // console.log(item);
      Object.values(buildingObj[item]).map((office) => {
        // console.log(office);
        array = [...array, ...office];
      });
      console.log("array");
      console.log(array);
      if (hasDuplicates(array)) {
        message = `Wing ${item} has contains 2 or more same office number`;
      }
    });
    if (message) {
      setWingSaving(false);
      toast.error(message);
    } else {
      let body = {
        floors_and_wings: buildingObj,
      };
      getApiData(`v1/buildings/${buildingId}/`, "put", body).then((res) => {
        setWingSaving(false);
        if (res.status) {
          toast.success(res.message);
        } else if (res.detail === "Invalid token.") {
          history.push("/logout");
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header pt-0">
              <h3 className="card-title align-items-center ">
                <span className="card-label fw-bolder fs-3 mb-1 w-150px">
                  No Of Wings:
                </span>
                {/*begin::Dialer*/}
                <div className="position-relative w-lg-150px text-center">
                  {/*begin::Decrease control*/}
                  <button
                    type="button"
                    className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                    onClick={() => onWingChange("decr")}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/general/gen042.svg*/}
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </button>
                  {/*end::Decrease control*/}
                  {/*begin::Input control*/}
                  <input
                    type="text"
                    className="form-control fs-5 text-center ps-0"
                    data-kt-dialer-control="input"
                    readOnly="readonly"
                    value={Object.keys(buildingObj).length}
                  />
                  {/*end::Input control*/}
                  {/*begin::Increase control*/}
                  <button
                    type="button"
                    className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                    onClick={() => onWingChange("incr")}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/general/gen041.svg*/}
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill="black"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="black"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </button>
                  {/*end::Increase control*/}
                </div>
                {/*end::Dialer*/}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            <div className="card-body">
              <div className=" row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={buildingName}
                          onChange={(e) => {
                            setBuildingName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={buildingAddr}
                          onChange={(e) => {
                            setBuildingAddr(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <div className="d-flex">
                          <label className="fw-bolder mb-1">Latitude</label>
                          <a
                            className="ml-auto"
                            onClick={() => {
                              getCurrentLocation();
                            }}
                          >
                            <i
                              className="fa fa-crosshairs fs-7 mr-2"
                              style={{ color: "black" }}
                            />
                            Get Current
                          </a>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          value={lat}
                          onChange={(e) => {
                            setLat(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label className="fw-bolder mb-1">Longitude</label>
                        <input
                          type="number"
                          className="form-control"
                          value={lon}
                          onChange={(e) => {
                            setLon(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building State
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedState}
                          handleChange={(obj) => {
                            setSelectedCity(null);
                            setSelectedArea(null);
                            setSelectedTapri(null);
                            setSelectedState(obj);
                          }}
                          list={stateList}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building City
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedCity}
                          handleChange={(obj) => {
                            setSelectedArea(null);
                            setSelectedTapri(null);
                            setSelectedCity(obj);
                          }}
                          list={cityList}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building Area
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedArea}
                          handleChange={(obj) => {
                            setSelectedTapri(null);
                            setSelectedArea(obj);
                          }}
                          list={areaList}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Select Tapri
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedTapri}
                          handleChange={(obj) => {
                            setSelectedTapri(obj);
                          }}
                          list={tapriList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <button
                    className="btn btn-primary px-10"
                    onClick={saveBuilding}
                    disabled={buildingSaving}
                  >
                    Save
                    {buildingSaving && (
                      <Spinner animation="border" size="sm" className="ml-1" />
                    )}
                  </button>
                  <Link
                    className="btn btn-light px-10 ms-3"
                    to="/master/building"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {Object.keys(buildingObj).map((wing, i) => {
        // console.log("length");
        let floorData = Object.keys(buildingObj[wing]);
        // console.log(floorData);
        return (
          <div className="row" id={`wing_${i}`}>
            <div className="col-md-12">
              <div className="card mb-5 mb-xl-10">
                <div className="card-header pt-0">
                  <h3 className="card-title align-items-center ">
                    <div className="d-flex align-items-center">
                      <span className="card-label fw-bolder fs-3">Wing</span>
                      <input
                        defaultValue={wing.toUpperCase()}
                        className="form-control ml-2 w-100px mr-1"
                        onBlur={(e) => {
                          if (e.target.value) {
                            let obj = { ...buildingObj };
                            obj[e.target.value] = obj[wing];
                            delete obj[wing];
                            // console.log(obj);
                            setBuildingObj(obj);
                          }
                        }}
                      />
                      :
                    </div>
                    {/*begin::Dialer*/}
                    <div className="position-relative w-lg-150px text-center ml-4">
                      {/*begin::Decrease control*/}
                      <button
                        type="button"
                        className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                        onClick={() => {
                          onFloorChange("decr", wing);
                        }}
                      >
                        {/*begin::Svg Icon | path: icons/duotune/general/gen042.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={10}
                              fill="black"
                            />
                            <rect
                              x="6.01041"
                              y="10.9247"
                              width={12}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </button>
                      {/*end::Decrease control*/}
                      {/*begin::Input control*/}
                      <input
                        type="text"
                        className="form-control fs-5 text-center ps-0"
                        value={floorData.length}
                      />
                      {/*end::Input control*/}
                      {/*begin::Increase control*/}
                      <button
                        type="button"
                        className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                        onClick={() => {
                          onFloorChange("incr", wing);
                        }}
                      >
                        {/*begin::Svg Icon | path: icons/duotune/general/gen041.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={10}
                              fill="black"
                            />
                            <rect
                              x="10.8891"
                              y="17.8033"
                              width={12}
                              height={2}
                              rx={1}
                              transform="rotate(-90 10.8891 17.8033)"
                              fill="black"
                            />
                            <rect
                              x="6.01041"
                              y="10.9247"
                              width={12}
                              height={2}
                              rx={1}
                              fill="black"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </button>
                      {/*end::Increase control*/}
                    </div>
                    {/*end::Dialer*/}
                  </h3>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  <div className=" row">
                    {floorData.length &&
                      floorData.map((floor) => {
                        return (
                          <div className="col-md-12">
                            <div className="row mb-3">
                              <div className="col-md-1  text-left mt-auto mb-auto">
                                <label className="fw-bolder mb-1 fs-5 mt-3">
                                  Floor {floor}
                                </label>
                              </div>
                              {buildingObj[wing][floor].map((office, i) => {
                                return (
                                  <div className="col ">
                                    <div
                                      className="input-group"
                                      style={{
                                        minWidth: "155px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <input
                                        id={`${i}_${Math.random()}`}
                                        type="text"
                                        className="form-control"
                                        placeholder="Floor Number"
                                        defaultValue={office}
                                        onChange={(e) => {
                                          buildingObj[wing][floor][i] =
                                            e.target.value;
                                        }}
                                        // onBlur={(e) => {
                                        //   console.log(e.target.value);
                                        //   console.log(buildingObj[wing]);
                                        //   buildingObj[wing][floor][i] = "mih";
                                        // }}
                                      />
                                      <span
                                        className="input-group-text bg-white cursor-pointer"
                                        onClick={() => {
                                          onOfficeChange(
                                            "remove",
                                            wing,
                                            floor,
                                            i
                                          );
                                        }}
                                      >
                                        <i className="far fa-trash-alt fs-7" />
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}

                              <div className="col-md-1 ">
                                <a
                                  onClick={() => {
                                    onOfficeChange("incr", wing, floor);
                                  }}
                                  className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger px-5 "
                                  style={{ marginTop: "10px" }}
                                >
                                  <i className="fa fa-plus fs-4 text-danger pe-0" />{" "}
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="col-md-12 mt-5">
                      <button
                        className="btn btn-primary px-10"
                        onClick={saveWing}
                        disabled={wingSaving}
                      >
                        Save
                        {wingSaving && (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="ml-1"
                          />
                        )}
                      </button>
                      {/* <Link
                        className="btn btn-light px-10 ms-3"
                        to="/master/building/list"
                      >
                        Cancel
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
