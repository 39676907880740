import React from "react";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";

export default function({ onHide, data }) {
  return (
    <Modal size="md" show={data.status} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          QR code for: {data.value}
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <QRCode value={data.value} />
        </div>
      </Modal.Body>
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-light font-weight-bold px-10"
          onClick={onHide}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
